import { AreasItemType } from '../../../../api/types/apiGetReport';
import cn from '../../../../lib/classNames';
import {
	DataPlaceholderHighRiskType,
	DataPlaceholderHospitalsType,
	DataPlaceholderSafestPlacesType,
} from '../../RiskBriefReportDemo/RiskBriefReportDemo';

import TabInfo from './TabInfo';

import styles from './LocationsDemo.module.scss';

type LocationsDemoType = {
	headerDescription: string;
	icon: string;
	dataDemo?: AreasItemType[];
	dataPlaceholder?:
		| DataPlaceholderHighRiskType[]
		| DataPlaceholderHospitalsType[]
		| DataPlaceholderSafestPlacesType[];
};

export default function LocationsDemo(props: LocationsDemoType) {
	const { headerDescription, icon, dataDemo, dataPlaceholder } = props;

	return (
		<div className={styles['locations-demo']}>
			<p className={cn(styles.description, styles['header-description'])}>{headerDescription}</p>
			<div className={styles['contacts-wrap']}>
				{dataDemo && <TabInfo data={dataDemo} icon={icon} />}
				{dataPlaceholder && (
					<TabInfo className="text-placeholder-wrap" data={dataPlaceholder} icon={icon} />
				)}
			</div>
		</div>
	);
}
