import { useState } from 'react';

import Layout from '../Layout/LayoutProfile';

import styles from './ProfilePlans.module.scss';

function ProfilePlans() {
	const [logout, setLogout] = useState(false);
	return (
		<Layout isLogout={logout}>
			<div className={styles['profile-plans-wrap']}>
				<h1 className={styles.header}>Subscription and plans</h1>
				<div className={styles.body}>coming soon</div>
			</div>
		</Layout>
	);
}

export default ProfilePlans;
