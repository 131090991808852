import { useEffect, useState } from 'react';

import { ROUTE } from '../../App';
import { Loader } from '../../components';
import Button from '../../components/Buttons/Button';
import LinkBox from '../../components/Buttons/LinkBox';
import Logotype from '../../components/Logotype';
import useMediaQuery from '../../hooks/MediaQuery';
import cn from '../../lib/classNames';

import headerBgDesktop from './images/headerBg.jpg';
import headerBgMobile from './images/headerBgMobile.jpg';
import MarketsBtn from './MarketsBtn';
import SectionHeader from './SectionHeader';

import styles from './Header.module.scss';

type HeaderType = {
	classPageContent?: string;
};

export default function Header(props: HeaderType) {
	const { classPageContent } = props;
	const [isLoading, setIsLoading] = useState(true);

	const { userAgent } = navigator;
	const isIOS = userAgent.includes('safeesteem-personal-edition-ios-app');

	const isMobile = useMediaQuery('(max-width: 767px)');

	useEffect(() => {
		const img = new Image();

		img.src = isMobile ? headerBgMobile : headerBgDesktop;

		img.onload = () => {
			setIsLoading(false);
		};
	}, []);

	return (
		<div className={cn(styles['header-wrap'], { [styles.loading]: isLoading })}>
			<Loader className={styles.loader} />
			<header className={styles.header}>
				<div className={cn(styles.content, classPageContent)}>
					<div className={styles['row-1']}>
						<div className={styles['logo-auth-wrap']}>
							<div className={styles.logo}>
								<Logotype color="dark" small={isMobile} height={isMobile ? 40 : 52} />
							</div>
							<div className={styles['auth-link']}>
								<LinkBox
									elementType="navigate"
									variant="text"
									color="primary"
									iconBefore="person_outline"
									href={ROUTE.signIn}
								>
									LOG IN
								</LinkBox>
							</div>
						</div>
					</div>
					<div className={styles['row-2']}>
						<div className={styles['col-1']}>
							<SectionHeader
								headingTag="h1"
								className={styles['main-header']}
								title="Your Safe Journey Starts Here"
								subTitle="Personalized risk intelligence and safety insights at home and abroad."
							/>
							<div className={styles['btn-sing-wrap']}>
								<Button
									elementType="navigate"
									variant="filled"
									color="secondary"
									href={ROUTE.signIn}
									size="large"
								>
									sign up for free
								</Button>
							</div>
							{!isIOS && (
								<MarketsBtn
									className={styles['market-btns-wrap']}
									classBtn={styles['market-btns']}
								/>
							)}
						</div>
					</div>
				</div>
			</header>
		</div>
	);
}
