import { Link } from 'react-router-dom';

import cn from '../../../lib/classNames';

import styles from './UserProfileDropdownActionRow.module.scss';

type UserProfileDropdownActionRowType = {
	label: string;
	link?: string;
	iconName?: string;
	onClick?: () => void;
};

export default function UserProfileDropdownActionRow(props: UserProfileDropdownActionRowType) {
	const { link, label, iconName, onClick } = props;

	const content = (
		<span className={styles.wrap} onClick={onClick}>
			<span className={cn(styles.label, 'overflow-row-1')} title={label}>
				{label}
			</span>
			<span className={cn(styles.icon, 'material-icons-round')}>{iconName || 'arrow_forward'}</span>
		</span>
	);

	return <div className={styles.row}>{link ? <Link to={link}>{content}</Link> : content}</div>;
}
