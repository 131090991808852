import { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import UserProfileDropdown from '../UserProfileDropdown/UserProfileDropdown';

import styles from './UserProfileHeader.module.scss';

type UserProfileHeaderType = {
	userName: string;
	avatar: string;
	logoUrl?: string;
	displayLogo?: boolean;
};

function UserProfileHeader(props: UserProfileHeaderType) {
	const { avatar, userName, logoUrl = '', displayLogo = false } = props;
	const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);

	return (
		<div className={styles.container} onClick={() => setDisplayDropdown((prev) => !prev)}>
			<div className={styles.avatar} style={{ backgroundImage: `url(${avatar})` }} />
			<div className={styles.name}>{userName}</div>
			{displayLogo && logoUrl && (
				<div className={styles.logo}>
					<img className={styles.logo} src={logoUrl} alt="" />
				</div>
			)}
			<div className={styles.arrow}>
				<span className="material-icons-outlined">
					{displayDropdown ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
				</span>
			</div>
			{displayDropdown && (
				<ClickAwayListener onClickAway={() => setDisplayDropdown(false)}>
					<div className={styles['dropdown-wrapper']}>
						<div className={styles.overlay} />
						<UserProfileDropdown />
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
}

export default UserProfileHeader;
