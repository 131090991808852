import AlertInteractive from '../../../components/AlertInteractive/AlertInteractive';

const TITLE_DEFAULT = 'Some report sections failed to generate';
const SUBTITLE_DEFAULT = 'You can try generating them again.';

export type FailedToGenerateWarningReportType = {
	title?: string;
	subTitle?: string;
};

export default function FailedToGenerateWarningReport(props: FailedToGenerateWarningReportType) {
	const { title = TITLE_DEFAULT, subTitle = SUBTITLE_DEFAULT } = props;
	return (
		<AlertInteractive
			type="warning"
			title={title}
			subTitle={subTitle}
			icon="warning_amber"
			closeButton
		/>
	);
}
