import { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { ROUTE } from '../../../App';
import { ButtonAppleAuth, ButtonGoogleAuth, TextField } from '../../../components';
import Button from '../../../components/Buttons/Button';
import { useWizard } from '../../../components/Wizard';
import { validateEmail } from '../../../lib';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';
import Header from '../Header';
import StepHeader from '../StepHeader';

import styles from '../GetStartedNew.module.scss';

type SignUpStep0Type = {
	step?: number;
	handleSetEmail: (email: any) => void;
};

export default function SignUpStep0(props: SignUpStep0Type) {
	const { nextStep } = useWizard();

	const [status, setStatus] = useState('');
	const [emailValue, setEmailValue] = useState('');
	const [emailErrorMsg, setEmailErrorMsg] = useState('');
	const [isEmptyEmail, setIsEmptyEmail] = useState(true);

	const refEmail = useRef<HTMLInputElement>();

	const APIsendConfirmCode = () => {
		api.sendConfirmCodeEmail({ email: emailValue }).then((response) => {
			setStatus(response?.data?.status ?? '');
		});
	};

	const emailVerification = () => {
		if (refEmail.current) {
			const email = refEmail.current.value;
			const result = validateEmail.validate({ email }).error;
			setEmailValue(email);
			setEmailErrorMsg(!result ? '' : 'Enter a valid email');
		}

		return null;
	};

	const handleSubmit = () => {
		emailVerification();

		if (emailValue && !emailErrorMsg) {
			props.handleSetEmail(emailValue);
			APIsendConfirmCode();
		}
	};

	const handleKeyPress = (event: { key: string }) => {
		if (event.key === 'Enter' && !isEmptyEmail) {
			handleSubmit();
		}
	};

	useEffect(() => {
		if (refEmail.current) {
			setEmailValue(refEmail.current.value);
			setIsEmptyEmail(!refEmail.current.value);
		}
	}, [refEmail.current?.value]);

	useEffect(() => {
		if (status === 'email_ownership_confirmation_required') {
			nextStep();
		} else {
			setEmailErrorMsg(
				status === 'profile_data_required' || status === 'ready' ? 'That email is taken' : '',
			);
		}
	}, [status]);

	return (
		<>
			<StepHeader step={props.step} />
			<div className="get-register-steps-container block-login">
				<div className="form-sign-in">
					<Header
						title="Create Account"
						description="Start your journey with Safe-xplore — your free plan is ready!"
					/>
					<div className="form-container form-auth-container">
						<div className={styles['form-inner']}>
							<div className={styles['field-wrap']}>
								<TextField
									inputRef={refEmail}
									label="Enter your email"
									onBlur={() => {
										emailVerification();
										setIsEmptyEmail(!refEmail.current?.value);
									}}
									onChange={() => {
										if (refEmail.current?.value) {
											setEmailValue(refEmail.current.value);
										}
									}}
									onKeyPress={handleKeyPress}
								/>
								{emailErrorMsg && <p className={styles['field-error']}>{emailErrorMsg}</p>}
							</div>
							<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
								<Button
									className={cn(
										styles['button'],
										styles['variant-filled'],
										styles['color-primary'],
									)}
									variant="filled"
									color="primary"
									onClick={() => {
										handleSubmit();
									}}
								>
									Confirm Your Email
								</Button>
							</div>
						</div>
					</div>
					<p className="text-terms">
						Already have an account? <Link to={ROUTE.signIn}>Sign in</Link>
					</p>
					<p className="text-auth">or</p>
					<div className="btn-auth-container">
						<ButtonGoogleAuth />
						<ButtonAppleAuth />
					</div>
				</div>
			</div>
		</>
	);
}
