import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ImageType } from 'react-images-uploading';

import { SquarePen } from 'lucide-react';
import { ApiGetCompanyType } from '../../api/types/apiGetCompany';
import { Context, GoogleReCaptcha, Loader, TagsInput, TextField } from '../../components';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutProfile';

import { UploadFile } from '.';

import styles from './Company.module.scss';

const isEqualStringArrays = (arr1: string[], arr2: string[]) => {
	if (arr1.length !== arr2.length) return false;
	return arr1.every((val, index) => val === arr2[index]);
};

const isValidDomain = (domain: string): boolean => {
	const domainRegex =
		/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
	return domainRegex.test(domain);
};

function Company() {
	const [logout, setLogout] = useState(false);
	const [nameValue, setNameValue] = useState('');
	const [businessUnitValue, setBusinessUnitValue] = useState<string[]>([]);
	const [emailDomainValue, setEmailDomainValue] = useState<string[]>([]);
	const [locationValue, setLocationValue] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);

	const [companyData, setCompanyData] = useState<ApiGetCompanyType | null>(null);
	const [isLoader, setIsLoader] = useState(true);

	const { setGoogleToken, userProfileData, setUserProfileData } = useContext(Context);

	const {
		name = '',
		emailDomains = [],
		businessUnits = [],
		location = '',
		logo = '',
	} = companyData || {};

	const getCompanyInfo = () => {
		api
			.getCompanyInfo()
			.then((response) => {
				if (response.ok) {
					setCompanyData(response.data);
					setIsLoader(false);
				}
			})
			.catch((err) => {
				console.error('*** getCompanyInfo > getCompanyInfo', err);
			});
	};

	useEffect(() => {
		getCompanyInfo();
	}, []);

	const token = useRef('');

	const handleCaptchaVerify = useCallback((newToken: string) => {
		token.current = newToken;
		setGoogleToken(newToken);
	}, []);

	const onSelect = (selectedFile: ImageType) => {
		if (selectedFile.length) {
			const formData = new FormData();
			formData.append('file', selectedFile[0].file);
			api.uploadCompanyLogo(formData).then((response) => {
				if (response.ok) {
					setUserProfileData({
						...userProfileData,
						company: { ...(userProfileData.company || {}), logo: response.data.avatarId },
					});
					setCompanyData((state) => {
						if (state === null) return null;
						return {
							...state,
							logo: response.data.avatarId,
						};
					});
				}
			});
		}
	};

	const onDeleteLogo = () => {
		api.deleteCompanyLogo().then((response) => {
			if (response.ok) {
				setUserProfileData({
					...userProfileData,
					company: { ...(userProfileData.company || {}), logo: null },
				});
				setCompanyData((state) => {
					if (state === null) return null;
					return {
						...state,
						logo: null,
					};
				});
			}
		});
	};

	const reset = useCallback(() => {
		setBusinessUnitValue(businessUnits || []);
		setEmailDomainValue(emailDomains || []);
		setLocationValue(location || '');
		setNameValue(name || '');
		setIsEmailError(false);
		setIsEditMode(false);
	}, [companyData]);

	useEffect(() => {
		reset();
	}, [companyData]);

	const editEmailDomain = useCallback(
		(index: number, value: string) => {
			setIsEmailError(false);
			setEmailDomainValue((state) => {
				const newState = [...state];
				newState[index] = value;
				return newState;
			});
		},
		[setEmailDomainValue],
	);

	const addEmailDomain = useCallback(() => {
		setEmailDomainValue((state) => {
			return [...state, ''];
		});
	}, [setEmailDomainValue]);

	const removeEmailDomain = useCallback(
		(index: number) => {
			setEmailDomainValue((state) => {
				const newState = [...state];
				newState.splice(index, 1);
				return newState;
			});
		},
		[setEmailDomainValue],
	);

	const isUpdateData = () => {
		if (
			isEqualStringArrays(businessUnitValue, businessUnits || []) &&
			isEqualStringArrays(emailDomainValue, emailDomains || []) &&
			locationValue === location &&
			name === nameValue
		) {
			return false;
		}
		return true;
	};

	const handleSubmit = useCallback(() => {
		setIsSubmitted(true);

		const isInvalidEmail = emailDomainValue.find((email) => !isValidDomain(email));
		if (isInvalidEmail) {
			setIsEmailError(true);
		} else {
			const payloadData = {
				name: nameValue,
				emailDomains: emailDomainValue,
				businessUnits: businessUnitValue,
				location: locationValue,
				logo,
			};

			api.uploadCompanyInfo(payloadData).then((response) => {
				if (response.ok) {
					setCompanyData(response.data);
				}
			});
		}
	}, [nameValue, emailDomainValue, businessUnitValue, locationValue, logo]);

	return (
		<Layout isLogout={logout}>
			{isLoader && <Loader size="small" />}
			<div className={cn(styles['company-wrap'], { [styles.load]: isLoader })}>
				{!token.current && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
				<h1 className={styles.header}>Company information</h1>
				<div className={styles['form-wrap']}>
					<div className={styles['title-wrap']}>
						<h3 className={styles.title}>Company information</h3>
						<span
							className={cn(styles['title-button'], { [styles.hide]: isEditMode })}
							onClick={() => setIsEditMode(!isEditMode)}
						>
							<SquarePen size={16} /> EDIT
						</span>
					</div>

					<h4 className={styles['sub-title']}>Manage and keep your company details up to date</h4>

					<div className={styles['avatar-wrap']}>
						{/* <div className={styles.avatar} style={{ backgroundImage: `url("${logo}")` }} /> */}
						<div className={styles.avatar}>{logo && <img src={logo} alt="Company logo" />}</div>

						<UploadFile
							maxFileSize={2000000}
							acceptType={['jpg', 'png', 'jpeg']}
							onSelect={onSelect}
						>
							<Button
								variant="filled"
								color="primary"
								sizeWidth="full"
								className={styles['avatar-upload-btn']}
							>
								Upload new logo
							</Button>
						</UploadFile>

						<a
							onClick={(e: any) => {
								onDeleteLogo();
								e.stopPropagation();
							}}
							className={cn(styles['avatar-delete-btn'], {
								[styles['avatar-disabled-delete-btn']]: isUpdateData(),
							})}
						>
							Delete logo
						</a>
					</div>
					<div className={styles.body}>
						<div className={styles['field-inline']}>
							<div className={cn(styles['field-wrap'], styles['field-wrap-gender'])}>
								<div className={styles['field-inner']}>
									<TextField
										label="Company Name"
										placeholder="Company Name"
										value={nameValue}
										onChange={setNameValue}
										disabled={!isEditMode}
									/>
									{isSubmitted && nameValue.length === 0 && (
										<p className={styles['field-error']}>Enter a company name</p>
									)}
								</div>
							</div>

							<div className={cn(styles['field-wrap'], styles['field-wrap-age'])}>
								<div className={styles['field-inner']}>
									<TextField
										label="Location"
										placeholder="Location"
										value={locationValue}
										onChange={setLocationValue}
										disabled={!isEditMode}
									/>
								</div>
							</div>
						</div>

						<div className={styles['field-inline']}>
							<div className={cn(styles['field-wrap'], styles['field-wrap-gender'])}>
								<div className={styles['field-inner']}>
									<TextField
										label="Domains"
										placeholder="Domains"
										value={emailDomainValue?.[0] || ''}
										onChange={(value: string) => editEmailDomain(0, value)}
										disabled={!isEditMode}
									/>

									{emailDomainValue.slice(1).map((emailDomain, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<div key={index} className={styles['domain-wrapper']}>
											<TextField
												value={emailDomain}
												onChange={(value: string) => editEmailDomain(index + 1, value)}
												disabled={!isEditMode}
											/>

											{isEditMode && (
												<a
													className={styles['remove-domain-button']}
													onClick={() => removeEmailDomain(index + 1)}
												>
													✕
												</a>
											)}
										</div>
									))}
									{isSubmitted && isEmailError && (
										<p className={styles['field-error']}>Enter a valid domain</p>
									)}
									{isEditMode && (
										<a className={styles['add-domain']} onClick={addEmailDomain}>
											+ Add domain
										</a>
									)}
								</div>
							</div>

							<div className={cn(styles['field-wrap'], styles['field-wrap-age'])}>
								<div className={styles['field-inner']}>
									<TagsInput
										value={businessUnitValue}
										onChange={setBusinessUnitValue}
										name="Business Unit"
										placeHolder="Business Unit"
										disabled={!isEditMode}
									/>
								</div>
							</div>
						</div>

						<div
							className={cn(styles['field-inline'], styles.buttons, { [styles.hide]: !isEditMode })}
						>
							<Button onClick={handleSubmit} variant="filled" color="primary" sizeWidth="full">
								SAVE
							</Button>

							<Button onClick={reset} variant="outlined" color="primary" sizeWidth="full">
								CANCEL
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Company;
