import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AlertsIcon } from '../../../../images/alerts_icon_gray.svg';
import { ROUTE } from '../../../App';
import { Context, Logotype, useOutsideClickEvent } from '../../../components';
import { useContextNavigation } from '../../../components/ContextNavigation';
import useMediaQuery from '../../../hooks/MediaQuery';
import { DEVICES } from '../../../lib/breakpoints';
import cn from '../../../lib/classNames';
import UserProfileHeader from '../UserProfileHeader/UserProfileHeader';
import { NotificationsStory } from '..';

import MenuItems from './MenuItems';

import './DashboardHeader.scss';

type DashboardHeaderType = {
	ageBucket: string;
	gender: string;
	picture: string;
	userName: string;
	className?: string;
};

export default function DashboardHeader(props: DashboardHeaderType) {
	const { ageBucket, gender, picture, userName, className } = props;

	const [menuMobileOpen, setMenuMobileOpen] = useState(false);
	const [notificationsStoreOpen, setNotificationsStoreOpen] = useState(false);

	const { isReportMenuVisible } = useContextNavigation();
	const { userProfileData, notificationsPersonalNewCount } = useContext(Context);

	const ref = useRef(null);

	const isDesktop = useMediaQuery(DEVICES.isDesktop);
	const isMobile = useMediaQuery(DEVICES.isMobile);

	const userCompany = userProfileData?.company !== null && userProfileData?.company;

	const handleClickOutside = () => {
		setTimeout(() => {
			setMenuMobileOpen(false);
		}, 100);
	};

	useOutsideClickEvent(ref, handleClickOutside);

	const renderAlerts = () => {
		return (
			<div className="notifications-alerts nav-link">
				<div className="notifications-alerts-content">
					<span
						className={notificationsPersonalNewCount ? 'new-notifications' : ''}
						onClick={() => setNotificationsStoreOpen(true)}
					>
						<div>
							<AlertsIcon className="bell-animate icons-animation" />
						</div>
					</span>
				</div>
			</div>
		);
	};

	return (
		<header className={cn('dashboard-header', 'header', 'header-top', className)}>
			<div className="block-logo">
				<Link to={ROUTE.default}>
					<Logotype className="se-logo" color="dark" small={isMobile} />
				</Link>
			</div>
			<div className="nav-menu block-btn">
				{!isMobile && isReportMenuVisible && (
					<div className="menu-desktop">
						<MenuItems />
					</div>
				)}

				<div className="btn-alerts-wrap">{renderAlerts()}</div>

				<div className="dashboard-header-br" />

				<div className="btn-profile-wrap">
					<UserProfileHeader
						userName={userName}
						avatar={picture}
						logoUrl={userCompany?.config?.logo_url}
					/>
				</div>

				<NotificationsStory
					notificationsStoreOpen={notificationsStoreOpen}
					notificationsStoreClose={setNotificationsStoreOpen}
				/>
			</div>
		</header>
	);
}
