import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import get from 'get-value';

import { adaptDataForDestinationReportApi } from '../../api/adapter';
import { ROUTE } from '../../App';
import { Context, GoogleReCaptcha } from '../../components';
import { useOrttoActivityCreateReport } from '../../context/ortto/hooks';
import { api } from '../../sagas';
import { LocationDataType } from '../../types/LocationData';
import LayoutStartedOld from '../Layout/LayoutStartedOld';

import AdditionStep0 from './Steps/AdditionStep0';
import AdditionStep3 from './Steps/AdditionStep3';
import GuessRisk from './GuessRisk';

type AdditionStepsType = {
	location: {
		state: {
			backTo?: string;
			inputPlace?: LocationDataType;
			inputStep?: number;
		};
	};
};

type StepsType = {
	[key: number]: JSX.Element;
};

const ALLOWED_TOTAL_VIEWED_LOCATIONS = 3;

function AdditionSteps(props: AdditionStepsType) {
	const { location } = props;

	const history = useHistory();

	const { inputPlace, inputStep = 0 } = location.state ?? {};

	const backTo = get(useLocation(), 'state.backTo', {
		default: ROUTE.dashboard,
	});

	const [step, setStep] = useState(inputStep);
	const [place, setPlace] = useState(inputPlace);
	const [goBack, setGoBack] = useState(false);
	const [checkboxSingUp] = useState(false);
	const [loaderShow, setLoaderShow] = useState(true);
	const [viewedLocations, setViewedLocations] = useState([]);
	const [reportUrl, setReportUrl] = useState('');
	const [isBuyNow, setIsBuyNow] = useState(false);
	const track = useOrttoActivityCreateReport();

	const { userProfileData, googleToken, isLogged } = useContext(Context);

	const locationHome = get(userProfileData, 'name');
	const locationDestination = place?.label;

	const token = useRef('');
	const handleCaptchaVerify = useCallback((newToken: string) => {
		token.current = newToken;
	}, []);

	const handleNextStep = useCallback((nextStep: number) => {
		setStep(nextStep);
	}, []);

	const handleSelectPlace = useCallback((newPlace: LocationDataType) => {
		setPlace(newPlace);
	}, []);

	const redirectToStripe = (link: string) => {
		if (link) {
			window.location.replace(link);
		}
	};

	const handleSubmit = useCallback(() => {
		const { seName, ageBucket, gender, id, name, lng, lat, company } = userProfileData;

		setLoaderShow(true);

		const queryParametersForReport = {
			username: seName,
			company: company?.name || '',
			ageBucket,
			gender,
			id,
			name,
			lng,
			lat,
			destId: place?.value?.id,
			destName: place?.value?.name,
			destLng: place?.value?.longitude,
			destLat: place?.value?.latitude,
		};

		if (isBuyNow) {
			api
				.buildPurchasedReportStub(queryParametersForReport)
				.then((response) => {
					const link = response.data.buyReportLink;
					redirectToStripe(link);
				})
				.catch((error) => {
					console.error('Error getting report:', error);
				});
		} else {
			if (loaderShow && isLogged) {
				handleNextStep(3);
			}

			handleNextStep(1);
			setReportUrl('');

			api
				.getHomeDestinationReport(queryParametersForReport, token.current)
				.then((response) => {
					if (response.ok) {
						const { reportId } = response.data;
						if (reportId && isLogged) {
							setReportUrl(`/home-destination-report/${reportId}`);
						}
					}
				})
				.catch((error) => {
					console.error('Error getting report:', error);
				})
				.finally(() => {
					setLoaderShow(false);
				});
		}
		track(locationDestination);
	}, [googleToken, place, checkboxSingUp, isLogged, loaderShow, handleNextStep, history, isBuyNow]);

	useEffect(() => {
		if (inputStep && !reportUrl) {
			return handleSubmit();
		}
	}, []);

	const guessHandleSubmit = (data: number) => {
		api.riskComparisonQuiz(
			adaptDataForDestinationReportApi({
				id: place?.value?.id,
				lat: place?.value?.latitude,
				lng: place?.value?.longitude,
				name: place?.value?.name,
				crimeRiskDiffPercent: data,
			}),
		);
	};

	const handleGoBack = useCallback(() => {
		setGoBack(true);
	}, []);

	const getDailyViewedLimitedList = () => {
		setLoaderShow(true);
		api.getDailyViewedLimitedList().then((response) => {
			if (response.ok) {
				setLoaderShow(false);
				setViewedLocations(response.data);
			}
		});
	};

	useEffect(() => {
		getDailyViewedLimitedList();
	}, []);

	const redirectToLoader = () => {
		if (step === 3) {
			if (reportUrl.length > 0) {
				history.push(reportUrl);
			} else {
				handleNextStep(3);
			}
		}
	};

	useEffect(() => {
		redirectToLoader();
	}, [step, reportUrl]);

	const handleGoToDestinationInput = useCallback((buyNow: boolean) => {
		setIsBuyNow(buyNow);
	}, []);

	const steps: StepsType = {
		0: (
			<AdditionStep0
				loaderShow={loaderShow}
				isBuyNow={isBuyNow}
				viewedLocations={viewedLocations}
				allowedTotalViewedLocations={ALLOWED_TOTAL_VIEWED_LOCATIONS}
				handleGoToDestinationInput={handleGoToDestinationInput}
				locationHome={locationHome}
				place={place}
				handleSelectPlace={handleSelectPlace}
				company={userProfileData.company}
				isLogged={isLogged}
				handleSubmit={handleSubmit}
				handleGoBack={handleGoBack}
			/>
		),

		1: (
			<GuessRisk
				home={locationHome}
				destination={locationDestination}
				step={setStep}
				variant="guess-step1"
				guessHandleSubmit={guessHandleSubmit}
			/>
		),

		2: (
			<GuessRisk
				home={locationHome}
				destination={locationDestination}
				step={setStep}
				variant="guess-step2"
			/>
		),
		3: <AdditionStep3 locationHome={locationHome} locationDestination={locationDestination} />,
	};

	useEffect(() => {
		const { classList } = document.getElementById('body')!;
		classList.add('bg-blue-medium-dark');
		return () => {
			classList.remove('bg-blue-medium-dark');
		};
	});

	return (
		<LayoutStartedOld>
			{!token.current && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
			<div className="intro-container">{steps[step]}</div>
			{goBack && <Redirect to={backTo} />}
		</LayoutStartedOld>
	);
}

export default AdditionSteps;
