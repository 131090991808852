import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { TextField } from '../../components';
import Button from '../../components/Buttons/Button';
import { validatePassword } from '../../lib';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Header from '../GetStarted/Header';
import Layout from '../Layout/LayoutProfile';

import styles from './ProfileChangePassword.module.scss';

export default function ProfileChangePassword() {
	const [passwordValue, setPasswordValue] = useState('');
	const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
	const [passwordHelpText, setPasswordHelpText] = useState({ typeText: 'field-error', text: '' });
	const [confirmPasswordHelpText, setConfirmPasswordHelpText] = useState({
		typeText: 'field-error',
		text: '',
	});
	const history = useHistory();

	const APIsetPassword = () => {
		api.updatePassword({ password: passwordValue }).then((response) => {
			if (response.ok) {
				history.push(ROUTE.profileManagement);
			} else {
				setPasswordHelpText((state) => ({
					...state,
					typeText: 'field-error',
					text: response?.data?.message || 'Something went wrong, please try again',
				}));
			}
		});
	};

	const handleSubmit = () => {
		const isValid = !validatePassword.validate({ password: passwordValue }).error;
		if (
			isValid &&
			passwordValue &&
			confirmPasswordValue &&
			passwordValue === confirmPasswordValue
		) {
			APIsetPassword();
		}
	};

	useEffect(() => {
		setPasswordHelpText((state) => ({ ...state, text: '' }));
		setConfirmPasswordHelpText((state) => ({ ...state, text: '' }));
		const isValid = !validatePassword.validate({ password: passwordValue }).error;

		if (passwordValue && !isValid) {
			setPasswordHelpText((state) => ({
				...state,
				typeText: 'field-help',
				text: '6 characters min. Must contain capital and regular letters and a number.',
			}));
		}

		if (passwordValue && confirmPasswordValue && passwordValue !== confirmPasswordValue) {
			setConfirmPasswordHelpText((state) => ({
				...state,
				typeText: 'field-error',
				text: 'Password do not match.',
			}));
		}
	}, [passwordValue, confirmPasswordValue]);

	return (
		<Layout>
			<div className={styles.container}>
				<div className="form-password">
					<Header
						title="Reset Password"
						description="Choose a strong password and do not use it for other accounts."
					/>
					<div className={styles['form-container']}>
						<div className={styles['form-inner']}>
							<div className={styles['field-wrap']}>
								<TextField
									label="New Password"
									type="password"
									value={passwordValue}
									onChange={setPasswordValue}
								/>

								{passwordHelpText.text && (
									<p className={cn(styles['field-help-text'], styles[passwordHelpText.typeText])}>
										{passwordHelpText.text}
									</p>
								)}
							</div>
							<div className={styles['field-wrap']}>
								<TextField
									label="Confirm new password"
									type="password"
									value={confirmPasswordValue}
									onChange={setConfirmPasswordValue}
								/>

								{confirmPasswordHelpText.text && (
									<p
										className={cn(
											styles['field-help-text'],
											styles[confirmPasswordHelpText.typeText],
										)}
									>
										{confirmPasswordHelpText.text}
									</p>
								)}
							</div>
							<div className={cn('field-wrap', 'field-wrap-btn')}>
								<Button
									className={cn(
										styles['button'],
										styles['variant-filled'],
										styles['color-primary'],
									)}
									variant="filled"
									color="primary"
									onClick={() => {
										handleSubmit();
									}}
								>
									Change password
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
