import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import Joi from 'joi-browser';
import moment from 'moment';

import Config from '../config';

export { default as localStore } from './localStore';
export { EventManager, EVENT } from './EventManager';

export const notificationsClasses = {
	advise: 'ADVISE',
	alert: 'ALERT',
	quizQuestion: 'QUIZ_QUESTION',
};

const debounce = (func, wait, immediate) => {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export { debounce };

export const log = (...props) => {
	if (Config.log) console.log(...props);
};

export const randomString = () => Math.random().toString(36).substring(7);

export const truncate = (str, n) => {
	return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const roundNumber = (v, l = 2) => {
	try {
		return Math.round(parseFloat(v) * 10 ** l) / 10 ** l;
	} catch {
		return v;
	}
};

export const getRoundingByCondition = (value) => {
	if (value || value === 0) {
		if (value === 0) {
			return value;
		}
		const valueAbs = Math.round(Math.abs(value));

		if (valueAbs > 0 && valueAbs < 8) {
			return value < 0 ? -5 : 5;
		}
		if (valueAbs.toString().length >= 3) {
			return Math.round(value / 10) * 10;
		}
		return Math.round(value / 5) * 5;
	}
};

export const formaterDate = (date, format = 'YYYYMMDD') => {
	return moment(date).format(format);
};

export const isEmptyObj = (obj) => {
	return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const isEmpty = (value) => {
	return (
		value === null ||
		value === undefined ||
		(typeof value === 'string' && value.trim().length === 0) ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' &&
			value !== null &&
			Object.keys(value).length === 0 &&
			Object.getPrototypeOf(value) === Object.prototype)
	);
};

export const validateEmail = Joi.object({
	// username: Joi.string()
	//     .alphanum()
	//     .min(3)
	//     .max(30)
	//     .required(),

	email: Joi.string()
		.regex(
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
		)
		.required(),
});

export const validatePassword = Joi.object({
	password: Joi.string()
		.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,100}$)/g)
		.required(),
});

export const allReplace = function (str, obj) {
	const keys = Object.keys(obj);

	keys.forEach((x) => {
		str = str.replace(new RegExp(x, 'g'), obj[x]);
	});
	return str;
};

// Split string
export const splitString = (str, separator) => {
	const arrayOfStrings = str.split(separator);

	return arrayOfStrings;
};

// const replaceChar = { ' ': '_', '-': '_', '\\...': '' };
// allReplace(x.label, replaceChar);

// Current Year
export const currentYear = new Date().getFullYear();

export const useMatchPath = (pathForCompare, currentPath) => {
	const location = useLocation();
	return matchPath(currentPath || location.pathname, {
		path: pathForCompare,
		exact: true,
		strict: false,
	});
};
