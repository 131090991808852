import { TagsInput } from 'react-tag-input-component';

import cn from '../lib/classNames';

import styles from './TagsInput.module.scss';

type TagsInputType = {
	value: string[];
	name: string;
	placeHolder?: string;
	onChange?: (tags: string[]) => void;
	className?: string;
	disabled?: boolean;
};

export default function Input(props: TagsInputType) {
	const { value, onChange, name, placeHolder, className, disabled } = props;
	return (
		<div className={cn(styles.wrapper, className)}>
			<label className={styles.label}>
				<span className={styles.text}>{placeHolder}</span>
			</label>
			<TagsInput name={name} value={value} onChange={onChange} disabled={disabled} />
		</div>
	);
}
