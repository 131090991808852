import { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CredentialResponse } from '@react-oauth/google';

import AppleIcon from '../../../images/icons/apple.svg';
import GoogleIcon from '../../../images/icons/google.svg';
import LogoutRoundedIcon from '../../../images/icons/logout-rounded.svg';
import {
	ButtonLinkedApple,
	ButtonLinkedGoogle,
	Context,
	Icon,
	ModalCustom,
} from '../../components';
import Button from '../../components/Buttons/Button';
import AntSwitch from '../../components/AntSwitch';
import { getGoogleUserDataFromCredential } from '../../components/ContextProvider.helper';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutProfile';
import { ROUTE } from '../../App';

import { AccountDelete } from '.';

import styles from './ProfileAccountManagement.module.scss';

enum ExternalServiceLinkType {
	apple = 'apple',
	google = 'google',
}

enum LinkStatusType {
	Connected = 'Connected',
	NotConnect = 'Not connect',
}

function ProfileAccountManagement() {
	const [logout, setLogout] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const { userProfileData, setUserProfileData, userLogout } = useContext(Context);

	const {
		appleId,
		googleId,
		emailNotificationsEnabled: emailNotification = false,
	} = userProfileData ?? {};
	const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(emailNotification);

	const handleNotificationsEnabled = () => {
		let flag = !emailNotificationsEnabled;

		api.enableEmailNotifications(flag ? 'true' : 'false').then((response) => {
			if (!response.ok) {
				flag = !flag;
				setEmailNotificationsEnabled(flag);
			}

			setUserProfileData({ ...userProfileData, emailNotificationsEnabled: flag });
		});

		setEmailNotificationsEnabled(flag);
	};

	const handleAccountDelete = () => {
		api.accountDeactivate().then((response) => {
			if (response.ok) {
				if (response.data.success) setLogout(true);
			}
		});
	};

	const handleAddGoogle = useCallback(
		(response: CredentialResponse) => {
			const res = getGoogleUserDataFromCredential(response);

			const data = {
				fbToken: null,
				fbEmail: null,
				fbName: null,
				fbAvatar: null,

				googleToken: res?.idToken,
				googleEmail: res?.email,
				googleAvatar: res?.picture,
				googleName: res?.name,
			};

			api.linkGoogleProfile(data).then((response) => {
				if (!response.ok) {
					// TODO: show error
				}
				setUserProfileData({ ...response.data });
			});
		},
		[setUserProfileData],
	);

	const handleDelete = (btnName: string) => {
		let unlinkAPI;

		if (btnName === ExternalServiceLinkType.apple) {
			unlinkAPI = api.unlinkAppleProfile();
		}

		if (btnName === ExternalServiceLinkType.google) {
			unlinkAPI = api.unlinkGoogleProfile();
		}

		if (unlinkAPI) {
			unlinkAPI.then((response) => {
				if (response.ok) {
					setUserProfileData({ ...response.data });
				}
			});
		}
	};

	const renderModal = (children?: JSX.Element) => {
		return (
			<ModalCustom open={showModal} stylesModal={{ alignItems: 'center' }}>
				{children}
			</ModalCustom>
		);
	};

	return (
		<Layout isLogout={logout}>
			<div className={styles['profile-account-management-wrap']}>
				<div className={styles.block}>
					<h2 className={styles['block-title']}>Email</h2>
					<h3 className={styles['block-sub-title']}>
						The email address associated with your account.
					</h3>
					<div className={styles['block-body']}>
						<span className={styles.email}>{userProfileData?.email}</span>
						<Link to={ROUTE.profileChangeEmail}>
							<Button variant="filled" color="primary" sizeWidth="content">
								Change email
							</Button>
						</Link>
					</div>
				</div>

				<div className={styles.block}>
					<h2 className={styles['block-title']}>Linked accounts</h2>
					<h3 className={styles['block-sub-title']}>
						Manage social networks linked to your profile for easy login.
					</h3>
					<div className={cn(styles['block-body'], styles['block-body-la'])}>
						<div className={styles['block-body-la-row']}>
							<div className={styles['block-body-la-cell']}>
								<Icon
									title="Google icon"
									url={GoogleIcon}
									className={styles['block-body-la-row-icon']}
								/>
								Google
							</div>
							<div className={styles['block-body-la-cell']}>
								{googleId ? LinkStatusType.Connected : LinkStatusType.NotConnect}
							</div>
							<div className={styles['block-body-la-cell']}>
								{googleId ? (
									<Button
										onClick={() => handleDelete(ExternalServiceLinkType.google)}
										disabled={!userProfileData.googleRemovable}
										variant="filled"
										color="primary"
										sizeWidth="content"
									>
										Disconnect
									</Button>
								) : (
									<ButtonLinkedGoogle onSuccess={handleAddGoogle!} />
								)}
							</div>
						</div>

						<div className={styles['block-body-la-row']}>
							<div className={styles['block-body-la-cell']}>
								<Icon
									title="Apple icon"
									url={AppleIcon}
									className={styles['block-body-la-row-icon']}
								/>
								Apple
							</div>
							<div className={styles['block-body-la-cell']}>
								{appleId ? LinkStatusType.Connected : LinkStatusType.NotConnect}
							</div>
							<div className={styles['block-body-la-cell']}>
								{appleId ? (
									<Button
										onClick={() => handleDelete(ExternalServiceLinkType.apple)}
										disabled={!userProfileData.appleRemovable}
										variant="filled"
										color="primary"
										sizeWidth="content"
									>
										Disconnect
									</Button>
								) : (
									<ButtonLinkedApple
										renderBtn={
											<Button variant="filled" color="primary" sizeWidth="content">
												Connect
											</Button>
										}
									/>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className={styles.block}>
					<h2 className={styles['block-title']}>Password</h2>
					<div className={styles['block-body']}>
						<h3 className={styles['block-sub-title']}>
							If you want to change your password, click the button.
						</h3>
						<Link to={ROUTE.profileChangePassword}>
							<Button variant="filled" color="primary" sizeWidth="content">
								Reset a password
							</Button>
						</Link>
					</div>
				</div>

				<div className={styles.block}>
					<h2 className={styles['block-title']}>Notifications</h2>
					<div className={styles['block-body']}>
						<h3 className={styles['block-sub-title']}>Receive updates and reminders via email.</h3>
						<AntSwitch
							checked={emailNotificationsEnabled}
							onChange={handleNotificationsEnabled}
							color="default"
						/>
					</div>
				</div>

				<div className={styles['bottom-block']}>
					<Button onClick={() => userLogout()} variant="filled" color="primary" sizeWidth="content">
						Log out
						<Icon title="Logout rounded icon" url={LogoutRoundedIcon} />
					</Button>
					<Button
						onClick={() => setShowModal(true)}
						variant="text"
						color="primary"
						sizeWidth="content"
						className={styles['bottom-block-delete-account']}
					>
						Delete account
					</Button>
				</div>
			</div>
			<ModalCustom open={showModal} stylesModal={{ alignItems: 'center' }}>
				<AccountDelete
					onSave={() => setShowModal(!showModal)}
					handleAccountDelete={handleAccountDelete}
				/>
			</ModalCustom>
		</Layout>
	);
}

export default ProfileAccountManagement;
