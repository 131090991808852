import { Component } from 'react';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import firebase from 'firebase/app';

import 'firebase/messaging';

import Page404 from './features/404';
import { Auth, AuthAppleLoading, AuthLoading } from './features/AuthLoading';
import { ConflictMap } from './features/ConflictMap';
import Stats from './features/Control/Stats';
import { DashboardPersonal } from './features/Dashboard';
import { AdditionSteps, ConfirmedEmail, ConfirmEmail, ConfirmNewPass } from './features/GetStarted';
import GetStartedStepsLoader from './features/GetStarted/GetStartedStepsLoader';
import GetRegisterNewSteps from './features/GetStarted/GetRegisterNewSteps';
import ExternalForm from './features/ExternalForm/ExternalForm';
import PageTests from './features/PageTests';
import {
	Company,
	CompanyMembers,
	Profile,
	ProfileHistory,
	ProfilePlans,
	ProfileAccountManagement,
	ProfileChangeEmail,
	ProfileChangePassword,
} from './features/Profile';
import { Report, ReportExpired } from './features/Report';
import Welcome from './features/Welcome/Welcome';
import VicriWidgetExternal from './widgetsExternal/VicriWidgetExternal';
import { GoogleAnalytics, GoogleReCaptcha, PageTitle } from './components';
import Config from './config';
import { EVENT, EventManager, localStore } from './lib';
import { api } from './sagas';
import createStore from './store';
import theme from './theme';

import './App.scss';

export const ROUTE = {
	default: '/',
	dashboard: '/dashboard',
	signIn: '/sign-in',
	signUp: '/sign-up',
	confirmNewPass: '/confirmation-password',
	confirmEmail: '/confirmation-email',
	confirmedEmail: '/confirmed-email',
	changeDestination: '/change-destination',
	report: '/report/:uuid',
	reportHomeDestination: '/home-destination-report/:uuid',
	reportHomeDestinationForm: '/home-destination-report-form/:uuid',
	reportExpired: '/report-expired',
	welcome: '/welcome',
	externalForm: '/external-form',
	appleLoginNotification: '/common/apple/login-notification-result',
	profile: '/profile',
	company: '/company',
	companyMembers: '/company-members',
	profileHistory: '/profile-history',
	profilePlans: '/profile-plans',
	profileManagement: '/profile-management',
	profileChangeEmail: '/profile-management-email',
	profileChangePassword: '/profile-management-password',
	linkProfileNotificationResult: '/common/apple/link-profile-notification-result',
	conflictMap: '/war-in-ukraine',
	page404: '/404',
	stats: '/stats',
	vicriWidget: '/vicri-widget',
	pageTests: '/page-test',
	about: '/about',
};

firebase.initializeApp(Config.firebase);

const store = createStore();
export { store };
class App extends Component {
	events = [];

	componentDidMount() {
		ReactGA.initialize(Config.googleAnalytics);
		this.getFcmTokenFromUrl();
		this.getFcmToken();
		this.subscribe();

		const timer = setInterval(() => {
			if (!this.props.context.googleToken) {
				window.location.reload();
			}
			clearInterval(timer);
		}, 5000);

		return () => {
			clearInterval(timer);
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.context.isLogged !== prevProps.context.isLogged) {
			this.handleFcmSendToken();
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	subscribe() {
		this.events.push(EventManager.subscribe(EVENT.FCM_SEND_TOKEN, this.handleFcmSendToken));
	}

	unsubscribe() {
		this.events.forEach((el) => {
			EventManager.unsubscribe(el);
		});
	}

	handleFcmSendToken = () => {
		const newToken = window.firebaseToken;
		const oldToken = localStore.getFirebaseToken();
		const state = store.getState();
		const logged = this.props.context.isLogged;
		console.log('=== App::handleFcmSendToken 1', { newToken, oldToken, logged });
		if (newToken && logged) {
			localStore.setFirebaseToken(newToken);
			api.notificationsToken(newToken);
			console.log('=== App::handleFcmSendToken 2');
		}
	};

	handleCaptchaVerify = (newToken) => {
		this.props.context.setGoogleToken(newToken);
		this.loadProfile(newToken);
		const query = window.location.search.substring(1);
		console.log('=== App::handleCaptchaVerify', { query });
	};

	getFcmTokenFromUrl = () => {
		const url = new URL(window.location.href);
		const token = url.searchParams.get('fcm_token');
		window.firebaseToken = token;
	};

	getFcmToken() {
		if (!window.firebaseToken && firebase.messaging.isSupported()) {
			const messaging = firebase.messaging();
			Notification.requestPermission().then((permission) => {
				if (permission === 'granted') {
					messaging
						.getToken()
						.then((firebaseToken) => {
							window.firebaseToken = firebaseToken;
							console.log('=== App::getFirebaseMerssagingToken', {
								firebaseToken,
							});
							messaging.onMessage((payload) => {
								console.log('Message received. ', payload);
							});
						})
						.catch((err) => {
							console.log('::: App >> err: ', err);
						});
				}
			});
		}
	}

	loadProfile(googleToken) {
		api
			.getProfile(googleToken)
			.then((response) => {
				if (response.ok && response.data) {
					this.props.context.setUserProfileData(response.data);
				}
			})
			.catch((err) => {
				console.log('*** App >> catch >> err >>>', err);
			})
			.finally(() => {
				this.props.context.setLoaderDecrement();
			});
	}

	render() {
		const { hearstManager } = this.props.context.userProfileData;
		return (
			<GoogleReCaptchaProvider reCaptchaKey={Config.reCaptchaKey}>
				<GoogleOAuthProvider clientId={Config.googleAuthId}>
					<Provider store={store}>
						<GoogleReCaptcha onVerify={this.handleCaptchaVerify} />
						<MuiThemeProvider theme={theme}>
							<Router>
								<GoogleAnalytics />
								<Switch>
									<Route
										path={ROUTE.default}
										render={(props) => (
											<PageTitle>
												<AuthLoading {...props} />
											</PageTitle>
										)}
										exact
									/>
									<Route
										path={ROUTE.welcome}
										render={(props) => (
											<PageTitle>
												<Welcome {...props} />
											</PageTitle>
										)}
										exact
									/>
									<Route
										path={ROUTE.dashboard}
										render={(props) => (
											<Auth>
												<PageTitle>
													<DashboardPersonal {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.signIn}
										render={(props) => (
											<PageTitle>
												<GetStartedStepsLoader {...props} />
											</PageTitle>
										)}
									/>
									<Route
										path={ROUTE.signUp}
										render={(props) => (
											<PageTitle>
												<GetRegisterNewSteps {...props} />
											</PageTitle>
										)}
									/>
									{/* <Route
										path={ROUTE.confirmNewPass}
										render={(props) => (
											<PageTitle>
												<ConfirmNewPass {...props} />
											</PageTitle>
										)}
									/>
									<Route
										path={ROUTE.confirmEmail}
										render={(props) => (
											<PageTitle>
												<ConfirmEmail {...props} />
											</PageTitle>
										)}
									/>
									<Route
										path={ROUTE.confirmedEmail}
										render={(props) => (
											<PageTitle>
												<ConfirmedEmail {...props} />
											</PageTitle>
										)}
									/> */}
									<Route
										path={ROUTE.changeDestination}
										render={(props) => (
											<Auth>
												<PageTitle>
													<AdditionSteps {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.report}
										render={(props) => (
											<Auth>
												<PageTitle>
													<Report {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.reportHomeDestination}
										render={(props) => (
											<Auth>
												<PageTitle>
													<Report {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.reportHomeDestinationForm}
										render={(props) => (
											<PageTitle>
												<ExternalForm {...props} />
											</PageTitle>
										)}
									/>
									<Route
										path={ROUTE.externalForm}
										render={(props) => (
											<PageTitle>
												<ExternalForm {...props} />
											</PageTitle>
										)}
									/>
									<Route
										path={ROUTE.reportExpired}
										render={(props) => (
											<PageTitle>
												<ReportExpired {...props} />
											</PageTitle>
										)}
									/>
									<Route path={ROUTE.appleLoginNotification} component={AuthAppleLoading} exact />
									<Route
										path={ROUTE.profile}
										render={(props) => (
											<Auth>
												<PageTitle>
													<Profile {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.company}
										render={(props) => (
											<Auth>
												<PageTitle>
													<Company {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.companyMembers}
										render={(props) => (
											<Auth>
												<PageTitle>
													<CompanyMembers {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.profileHistory}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ProfileHistory {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.profilePlans}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ProfilePlans {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.profileManagement}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ProfileAccountManagement {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.profileChangeEmail}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ProfileChangeEmail {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path={ROUTE.profileChangePassword}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ProfileChangePassword {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route exact path={ROUTE.linkProfileNotificationResult}>
										{({ location }) => {
											const linkedError = new URLSearchParams(document.location.search).get(
												'error',
											);

											return (
												<Redirect
													to={{
														pathname: ROUTE.profile,
														state: {
															linkedError: linkedError || '',
														},
													}}
												/>
											);
										}}
									</Route>
									<Route
										path={ROUTE.stats}
										render={(props) =>
											hearstManager ? (
												<Auth>
													<PageTitle>
														<Stats {...props} />
													</PageTitle>
												</Auth>
											) : (
												<AuthLoading {...props} />
											)
										}
									/>
									<Route
										path={ROUTE.conflictMap}
										render={(props) => (
											<Auth>
												<PageTitle>
													<ConflictMap {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route path={ROUTE.vicriWidget} component={VicriWidgetExternal} />
									<Route
										path={ROUTE.pageTests}
										render={(props) => (
											<Auth>
												<PageTitle>
													<PageTests {...props} />
												</PageTitle>
											</Auth>
										)}
									/>
									<Route
										path="/404"
										render={(props) => (
											<PageTitle>
												<Page404 {...props} />
											</PageTitle>
										)}
									/>
									<Redirect to="/404" />
								</Switch>
							</Router>
						</MuiThemeProvider>
					</Provider>
				</GoogleOAuthProvider>
			</GoogleReCaptchaProvider>
		);
	}
}

export default App;
