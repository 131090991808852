import { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTE } from '../../../App';
import { TextField } from '../../../components';
import Button from '../../../components/Buttons/Button';
import { useWizard } from '../../../components/Wizard';
import { localStore, validatePassword } from '../../../lib';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';
import Header from '../Header';
import StepHeader from '../StepHeader';

import styles from '../GetStartedNew.module.scss';

type SignUpStep2Type = {
	step?: number;
	email: string;
	tmpApiToken: string;
};

export default function SignUpStep2(props: SignUpStep2Type) {
	const [status, setStatus] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [isPasswordError, setIsPasswordError] = useState(false);
	const [passwordHelpText, setPasswordHelpText] = useState({ typeText: 'field-error', text: '' });

	const refPassword = useRef<HTMLInputElement>();
	const { nextStep } = useWizard();

	const setApiToken = (apiToken: string) => {
		api.apiInstance.setHeader('Authorization', apiToken);
		localStore.setToken(apiToken);
		localStore.setLoggedAs('SE');
	};

	const APIsetPassword = () => {
		api
			.setPassword({ name: props.email, password: passwordValue, token: props.tmpApiToken })
			.then((response) => {
				const resStatus = response?.data?.status ?? '';
				const apiToken = response?.headers?.authorization ?? '';

				if (!response.ok) {
					setPasswordHelpText((state) => ({
						...state,
						typeText: 'field-error',
						text: response?.data?.message || 'Something went wrong, please try again',
					}));
				} else if (resStatus === 'profile_data_required') {
					nextStep();
					setApiToken(apiToken);
				} else {
					setStatus(resStatus);
				}
		});
	};

	const renderPasswordHelpText = () => {
		setPasswordHelpText((state) => ({ ...state, text: '' }));

		if (passwordValue && isPasswordError) {
			setPasswordHelpText((state) => ({
				...state,
				typeText: 'field-help',
				text: '6 characters min. Must contain capital and regular letters and a number.',
			}));
		}
	};

	const handleSubmit = () => {
		if (!isPasswordError) {
			APIsetPassword();
		}
	};

	const passwordVerification = () => {
		const result = validatePassword.validate({ password: passwordValue }).error;
		setIsPasswordError(!!result);
		return !result;
	};

	const handleKeyPress = (event: { key: string }) => {
		if (event.key === 'Enter' && !isPasswordError) {
			handleSubmit();
		}
	};

	useEffect(() => {
		passwordVerification();
		renderPasswordHelpText();
	}, [isPasswordError, passwordValue]);

	if (status === 'ready') return <Redirect to={ROUTE.dashboard} />;

	return (
		<>
			<StepHeader step={props.step} />
			<div className="get-register-steps-container block-login">
				<div className="form-password">
					<Header
						title="Set up your account"
						description="To provide you with the best service, we need a little more information for calculate your personalized risk estimates. It will only take a minute!"
					/>
					<div className="form-container form-auth-container">
						<div className={styles['form-inner']}>
							<div className={styles['field-wrap']}>
								<TextField
									className="email-field"
									label="Enter your email"
									value={props.email}
									disabled
								/>
							</div>
							<div className={styles['field-wrap']}>
								<TextField
									inputRef={refPassword}
									label="Create password"
									type="password"
									value={passwordValue}
									onChange={() => {
										setIsPasswordError(false);
										setPasswordHelpText((state) => ({ ...state, text: '' }));
										setPasswordValue(refPassword.current ? refPassword.current.value : '');
									}}
									onFocus={() => renderPasswordHelpText()}
									onKeyPress={handleKeyPress}
								/>

								{passwordHelpText.text && (
									<p className={cn(styles['field-help-text'], styles[passwordHelpText.typeText])}>
										{passwordHelpText.text}
									</p>
								)}
							</div>
							<div className={cn('field-wrap', 'field-wrap-btn')}>
								<Button
									className={cn(
										styles['button'],
										styles['variant-filled'],
										styles['color-primary'],
									)}
									disabled={isPasswordError}
									variant="filled"
									color="primary"
									onClick={() => {
										handleSubmit();
									}}
								>
									Continue
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
