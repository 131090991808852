import { useState } from 'react';

import { Loader } from '../../components';
import { debounce } from '../../lib';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutProfile';

import { UsersRequest,UsersResponse, UserTable } from './UserTable/UserTable';

import styles from './CompanyMembers.module.scss';

function CompanyMembers() {
	const [logout, setLogout] = useState(false);
	const [members, setMembers] = useState<UsersResponse | undefined>();
	const [isLoader, setIsLoader] = useState(true);

	const getMembers = (params: object) => {
		setIsLoader(true);
		api
			.getCompanyUsers(params)
			.then((response) => {
				if (response.ok) {
					setMembers(response.data);
					setIsLoader(false);
				}
			})
			.catch((err) => {
				console.error('*** getMembers > getMembers', err);
			});
	};

	const debounceGetMembers = debounce(getMembers, 300);

	return (
		<Layout isLogout={logout}>
			{isLoader && <Loader size="small" />}
			<div className={cn(styles['company-members-wrap'], { [styles.load]: isLoader })}>
				<h1 className={styles.header}>Company members</h1>
				<div className={styles.body}>
					<UserTable
						data={members}
						handleFetch={(params: UsersRequest) =>
							debounceGetMembers({ ...params, page: (params?.page || 0) - 1 })
						}
					/>
				</div>
			</div>
		</Layout>
	);
}

export default CompanyMembers;
