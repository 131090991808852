import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { appStore, googlePlay } from '../../../images/welcome';
import { ROUTE } from '../../App';
import { Logotype } from '../../components';
import cn from '../../lib/classNames';

import styles from './LayoutStarted.module.scss';

const { userAgent } = navigator;
const isIOS = userAgent.includes('safeesteem-personal-edition-ios-app');

type LayoutStartedType = {
	children: ReactNode;
	displayStoreButtons?: boolean;
};

export default function LayoutStarted(props: LayoutStartedType) {
	const { children, displayStoreButtons = false } = props;

	return (
		<div className={styles['page-get-started']}>
			<div className={cn(styles.content, 'content')}>
				<header className={styles['header-top']}>
					<Link className={styles.logo} to={ROUTE.default}>
						<Logotype color="dark" />
					</Link>
				</header>
				<main className={styles.main}>
					<div className={styles['box-left']}>{children}</div>
					<div className={cn(styles['box-right'], 'mob-hide')}>
						<div>
							<h1>
								<span>Safe-xplore AI</span>
							</h1>
							<p>
								Live and travel with confidence by understanding your risks at home or when
								traveling.
							</p>
							{displayStoreButtons && !isIOS && (
								<div className={styles['app-download-container']}>
									<div className={styles['btn-store']}>
										<a href="https://apple.co/3pQ5dya">
											<img src={appStore} alt="Download on the App Store" />
										</a>
									</div>
									<div className={styles['btn-store']}>
										<a href="https://play.google.com/store/apps/details?id=com.safexplore">
											<img src={googlePlay} alt="" />
										</a>
									</div>
								</div>
							)}
							<a
								className={styles.link}
								href="https://safe-esteem.typeform.com/sxpeintro"
								target="_blank"
								rel="noreferrer"
							>
								Learn more
							</a>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
