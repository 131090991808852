import { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context, GoogleReCaptcha, Loader } from '../../components';
import LayoutStarted from '../Layout/LayoutStarted';

import Forgot from './Forgot';
import SignIn from './SignIn';

import './GetStartedNew.module.scss';

export type NameValueType = {
	name: string;
	value: string;
};

function GetStartedNew() {
	const [gotoDashboard, setGotoDashboard] = useState(false);
	const [isForgotPassword, setIsForgotPassword] = useState(false);

	const { userProfileData, googleToken, setGoogleToken, isLogged, firstStepDataExist, isLoader } =
		useContext(Context);

	useEffect(() => {
		if (isLogged && firstStepDataExist) setGotoDashboard(true);
	}, [isLogged, userProfileData]);

	const handleCaptchaVerify = useCallback((newToken: string) => {
		setGoogleToken(newToken);
	}, []);

	const onForgot = (isForgot: boolean) => {
		setIsForgotPassword(isForgot);
	};

	if (gotoDashboard) return <Redirect to={ROUTE.dashboard} />;

	if (isLoader) return <Loader />;

	return (
		<LayoutStarted displayStoreButtons>
			{!googleToken && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}

			<div className="get-started-steps-container block-login">
				{!isForgotPassword ? (
					<SignIn onForgot={onForgot} />
				) : (
					<Forgot onForgot={onForgot} isForgotPassword={isForgotPassword} />
				)}
			</div>
		</LayoutStarted>
	);
}

export default GetStartedNew;
