import { ButtonAppleAuth, ButtonGoogleAuth } from '../../components';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../App';

import Header from './Header';
import SignInAuth from './SignInAuth';

type SignInType = {
	onForgot: (isForgot: boolean) => void;
};

export default function SignIn(props: SignInType) {
	const { onForgot } = props;
	return (
		<div className="form-sign-in">
			<Header
				title="Sign In To Safe-xplore"
				description="Login to your Safe-xplore account with email"
			/>
			<div className="form-container form-auth-container">
				<SignInAuth onForgot={onForgot} />
			</div>
			<p className="text-auth">or</p>
			<div className="btn-auth-container">
				<ButtonGoogleAuth />
				<ButtonAppleAuth />
			</div>
			<p className="text-terms">
				Don&apos;t have an account? <Link to={ROUTE.signUp}>Register</Link>
			</p>
		</div>
	);
}
