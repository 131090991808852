import cn from '../../../../lib/classNames';

import styles from './ReportTitle.module.scss';

type ReportTitleType = {
	className?: string;
	title: string;
	personName: string;
	date: string;
};

export default function ReportTitle(props: ReportTitleType) {
	const { className, title, personName, date } = props;

	const userName = personName || 'anonymous';
	const isPreloader = !personName || !date;
	const subTitle = isPreloader
		? 'Processing...'
		: `Produced by Safe-xplore exclusively for ${userName} on ${date}`;

	return (
		<section className={cn(className, styles['report-title'])}>
			<h1 className={styles.title}>{title}</h1>
			{subTitle && <p className={styles['sub-title']}>{subTitle}</p>}
		</section>
	);
}
