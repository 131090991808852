import { ElementType } from 'react';

import cn from '../lib/classNames';

import styles from './Icon.module.scss';

type IconType = {
	className?: string;
	title: string;
	url?: string;
	slag?: string;
	svgProps?: object;
	component?: ElementType;
};

function Icon(props: IconType) {
	const {
		className,
		title,
		slag,
		url,
		svgProps = {},
		component: Component = null,
		...options
	} = props;

	// eslint-disable-next-line no-nested-ternary
	const IconComponent = url ? (
		<img src={url} alt={title} {...options} />
	) : Component ? (
		<Component role="img" title={title} {...svgProps} />
	) : null;

	return <span className={cn(styles['icon-wrapper'], className)}>{IconComponent}</span>;
}

export default Icon;
