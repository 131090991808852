import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

type PageTitleType = {
	children: ReactNode;
};

type PageTitletype = {
	[key: string]: string;
};

export default function PageTitle(props: PageTitleType) {
	const { children } = props;

	const location = useLocation();

	const pathName = `/${location.pathname.split('/')[1]}`;

	const pageTitle: PageTitletype = {
		'/': 'Welcome - Safe-xplore AI',
		'/dashboard': 'Dashboard - Safe-xplore AI',
		'/sign-in': 'Sign In - Safe-xplore AI',
		'/sign-up': 'Sign Up - Safe-xplore AI',
		'/confirmation-password': 'New password confirmed',
		'/confirmation-email': 'Thank you for signing up!',
		'/confirmed-email': 'Email confirmed',
		'/change-destination': 'Choose Location - Safe-xplore AI',
		'/welcome': 'Live and Travel With Confidence',
		'/externalForm': 'External form',
		'/profile': 'Profile Management - Safe-xplore AI',
		'/company': 'Company information - Safe-xplore AI',
		'/company-members': 'Company members - Safe-xplore AI',
		'/profile-history': 'Profile Management - Safe-xplore AI',
		'/profile-management': 'Profile Management - Safe-xplore AI',
		'/profile-change-password': 'Profile Management - Safe-xplore AI',
		'/war-in-ukraine': 'War in Ukraine',
		'/home-destination-report': 'Travel Risk Report — Safe-xplore AI',
		'/report-expired': 'Report Expired',
		'/stats': 'Analytics',
		'/404': '404 - Safe-xplore AI',
	};

	document.title = pageTitle[pathName] || 'Safe-xplore AI';

	return children;
}
