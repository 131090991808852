import { useCallback, useEffect, useState } from 'react';
import InputLabel from "@material-ui/core/InputLabel";

import { Select, SelectLocation, TextField } from '../../../components';
import Button from '../../../components/Buttons/Button';
import InfoLGBTQI from '../../../components/InfoLGBTQI';
import { useWizard } from '../../../components/Wizard';
import useMediaQuery from '../../../hooks/MediaQuery';
import { DEVICES } from '../../../lib/breakpoints';
import cn from '../../../lib/classNames';
import { AGE_BUCKET, GENDER_BUCKET } from '../../../lib/constats';
import { api } from '../../../sagas';
import { LocationDataType } from '../../../types/LocationData';
import Header from '../Header';
import StepHeader from '../StepHeader';

import styles from '../GetStartedNew.module.scss';

type SignUpStep3Type = {
	step?: number;
	isAppleAuth?: boolean;
};

export default function SignUpStep3(props: SignUpStep3Type) {
	const [status, setStatus] = useState('');
	const [nameValue, setNameValue] = useState('');
	const [ageValue, setAgeValue] = useState('');
	const [genderValue, setGenderValue] = useState('');
	const [isValidData, setIsValidData] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const { nextStep } = useWizard();
	const [place, setPlace] = useState<LocationDataType>();
	const isMobile = useMediaQuery(DEVICES.isMobile);

	const APIuploadProfile = () => {
		api
			.uploadProfile({
				name: nameValue,
				ageBucket: ageValue,
				gender: genderValue,
				locationId: place?.value?.id,
				locationName: place?.value?.name,
				locationLongitude: place?.value?.longitude,
				locationLatitude: place?.value?.latitude,
			})
			.then((response) => {
				const resStatus = response?.data?.status ?? '';

				if (response.ok) {
					nextStep();
				} else {
					// TODO: add handler
					setStatus(resStatus);
				}
			});
	};

	useEffect(() => {
		setIsValidData(!!(nameValue && place?.value?.id && ageValue && genderValue));
		setIsChanged(
			nameValue !== '' || place?.value?.id !== undefined || ageValue !== '' || genderValue !== '',
		);
	}, [nameValue, place, ageValue, genderValue]);

	const handleSubmit = () => {
		setIsSubmitted(true);
		if (isValidData) {
			APIuploadProfile();
		}
	};

	const handleSelectPlace = useCallback((newPlace: LocationDataType) => {
		setPlace(newPlace);
	}, []);

	return (
		<>
			<StepHeader skip={props.isAppleAuth ? 1 : undefined} step={props.step} />
			<div className="get-register-steps-container block-login step4">
				<div className="form-sign-in">
					<Header
						title="Set up your account"
						description="To provide you with the best service, we need a little more information for calculate your personalized risk estimates. It will only take a minute!"
					/>
					<div className="form-container form-auth-container">
						<div className={styles['form-inner']}>
							<div className={styles['field-wrap']}>
								<TextField
									className="email-field"
									label="Full name"
									placeholder="Enter your full name"
									onChange={setNameValue}
									focused
								/>
								{isSubmitted && nameValue.length === 0 && (
									<p className={styles['field-error']}>Enter a valid name</p>
								)}
							</div>
							<div className={cn(styles['field-wrap'], 'field-wrap-location', 'select-field')}>
								<div className="label">Home city</div>
								<SelectLocation
									placeholder="Enter your home city"
									place={place}
									onSelect={handleSelectPlace}
									withoutBorder
								/>
								{isSubmitted && !place?.value?.id && (
									<p className={styles['field-error']}>Enter a valid city</p>
								)}
							</div>
							<div className={cn(styles['field-wrap'], 'select-field', 'select-gender')}>
								<InputLabel htmlFor="" className="lgbtqi-label">
									Gender <InfoLGBTQI isMobile={isMobile} />
								</InputLabel>
								<Select
									placeholder="Select Gender"
									value={genderValue}
									items={GENDER_BUCKET}
									onSelect={setGenderValue}
								/>
								{isSubmitted && ageValue.length === 0 && (
									<p className={styles['field-error']}>Select a gender</p>
								)}
							</div>
							<div className={cn(styles['field-wrap'], 'select-field')}>
								<InputLabel htmlFor="">Age group</InputLabel>
								<Select
									placeholder="Choose your age group"
									value={ageValue}
									items={AGE_BUCKET}
									onSelect={setAgeValue}
								/>
								{isSubmitted && ageValue.length === 0 && (
									<p className={styles['field-error']}>Select an age group</p>
								)}
							</div>
							<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
								<Button
									className={cn(
										styles['button'],
										styles['variant-filled'],
										styles['color-primary'],
									)}
									disabled={!isChanged}
									variant="filled"
									color="primary"
									onClick={() => {
										handleSubmit();
									}}
								>
									Create account
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
