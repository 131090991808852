import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';

import HistoryIcon from '../../../images/icons/history.svg';
import HistoryFillIcon from '../../../images/icons/history-fill.svg';
import ManagementIcon from '../../../images/icons/management.svg';
import ManagementFillIcon from '../../../images/icons/management-fill.svg';
import PlansIcon from '../../../images/icons/plans.svg';
import PlansFillIcon from '../../../images/icons/plans-fill.svg';
import ProfileIcon from '../../../images/icons/profile.svg';
import ProfileFillIcon from '../../../images/icons/profile-fill.svg';
import CompanyIcon from '../../../images/icons/company.svg';
import CompanyFillIcon from '../../../images/icons/company-fill.svg';
import CompanyMemberIcon from '../../../images/icons/company-member.svg';
import CompanyMemberFillIcon from '../../../images/icons/company-member-fill.svg';
import { ROUTE } from '../../App';
import { Icon, Context } from '../../components';
import { isEmpty } from '../../lib';
import Button from '../../components/Buttons/Button';
import { useMatchPath } from '../../lib';
import cn from '../../lib/classNames';
import { DashboardHeaderWithActions, Footer } from '../Dashboard';

import styles from './LayoutProfile.module.scss';
import UserPlan from '../../components/UserPlan/UserPlan';

type SideMenuItemType = {
	iconUri: string;
	iconFillUri: string;
	title: string;
	to: string;
	child?: string[];
};

function SideMenuItem(props: SideMenuItemType) {
	const { title, to, iconUri, iconFillUri } = props;
	let isSelected = !!useMatchPath(to);
	if (!isSelected && !isEmpty(props.child)) {
		isSelected = !!props.child?.find((value) => !!useMatchPath(value));
	}

	return (
		<div
			className={cn(styles['side-menu-item'], {
				[styles['side-menu-item-selected']]: isSelected,
			})}
		>
			<Link to={to}>
				<Icon
					title={`${title} icon`}
					url={isSelected ? iconFillUri : iconUri}
					className={styles['side-menu-item-icon']}
				/>
				{title}
			</Link>
		</div>
	);
}

type LayoutProfileType = {
	children: ReactNode;
	isLogout?: boolean;
};

export default function LayoutProfile(props: LayoutProfileType) {
	const { children, isLogout } = props;
	const { userProfileData, isAdmin } = useContext(Context);

	const logo = userProfileData?.company?.logo;

	// TODO: use real data when API is ready
	const planName = "You're using corporate plan";
	const totalPlans = 50;
	const yourPlans = 32;

	return (
		<div className={styles['layout-profile']}>
			<div className={cn(styles.content, 'content')}>
				<DashboardHeaderWithActions isLogout={isLogout} className={styles.header} />
				<main className={styles.main}>
					<div className={styles['box-left']}>
						<div className={styles['side-menu']}>
							{logo && (
								<div className={styles.logo}>
									<img src={logo} alt="Company logo" />
								</div>
							)}
							<SideMenuItem
								title="Profile"
								to={ROUTE.profile}
								iconUri={ProfileIcon}
								iconFillUri={ProfileFillIcon}
							/>
							{isAdmin && (
								<SideMenuItem
									title="Company information"
									to={ROUTE.company}
									iconUri={CompanyIcon}
									iconFillUri={CompanyFillIcon}
								/>
							)}
							{isAdmin && (<SideMenuItem
									title="Company members"
									to={ROUTE.companyMembers}
									iconUri={CompanyMemberIcon}
									iconFillUri={CompanyMemberFillIcon}
								/>
							)}
							<SideMenuItem
								title="Reports history"
								to={ROUTE.profileHistory}
								iconUri={HistoryIcon}
								iconFillUri={HistoryFillIcon}
							/>
							{/* <SideMenuItem
								title="Subscription and plans"
								to={ROUTE.profilePlans}
								iconUri={PlansIcon}
								iconFillUri={PlansFillIcon}
							/> */}
							<SideMenuItem
								title="Account management"
								to={ROUTE.profileManagement}
								child={[ROUTE.profileChangeEmail, ROUTE.profileChangePassword]}
								iconUri={ManagementIcon}
								iconFillUri={ManagementFillIcon}
							/>
						</div>
						{/* TODO: hide the component until the API is ready */}
						{/* <UserPlan planName={planName} totalPlans={totalPlans} yourPlans={yourPlans} /> */}
					</div>
					<div className={styles['box-right']}>{children}</div>
				</main>
			</div>
		</div>
	);
}
