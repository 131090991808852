import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context } from '../../components';

import { api } from '../../sagas';

import DashboardHeader from './DashboardHeader/DashboardHeader';

type DashboardHeaderWithActionsType = {
	isLogout?: boolean;
	className?: string;
};

function DashboardHeaderWithActions(props: DashboardHeaderWithActionsType) {
	const { isLogout, className } = props;

	const { unconfirmedEmail, userLogout, userName, userGender, userProfileData, userAvatar } =
		useContext(Context);

	const history = useHistory();

	const logOut = () => {
		if ('firebaseToken' in window) {
			const { firebaseToken } = window;
			if (firebaseToken) {
				api.deleteFcmToken({ token: firebaseToken }).then((response) => {
					if (response.ok) {
						console.log('::: DashboardHeaderWithActions >> response: ', response);
					} else {
						// error
					}
				});
			}
		}

		if (unconfirmedEmail) {
			history.push(ROUTE.confirmEmail);
		} else {
			history.push(ROUTE.signIn);
		}
	};

	useEffect(() => {
		if (isLogout) {
			userLogout();
			logOut();
		}
	}, [isLogout]);

	return (
		<DashboardHeader
			ageBucket={userProfileData.ageBucket}
			gender={userGender.genderFullLabel}
			picture={userAvatar}
			userName={userName}
			className={className}
		/>
	);
}

export default DashboardHeaderWithActions;
