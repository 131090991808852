import { AiReportType } from '../../../../api/types/apiGetReport';
import cn from '../../../../lib/classNames';
import Tabs from '../../components/ui/Tabs/Tabs';
import { DataPlaceholderType } from '../../RiskBriefReportDemo/RiskBriefReportDemo';
import FailedToGenerateWarningSection from '../FailedToGenerateWarningSection';

import HighRisk from './HighRisk';
import Hospitals from './Hospitals';
import LocationsDemo from './LocationsDemo';
import SafestPlaces from './SafestPlaces';

import styles from './Locations.module.scss';
import { hasErrorField } from '../utils';

export type reportVariants = 'demo';

export type LocationsType = {
	className?: string;
	data: AiReportType;
	dataPlaceholder?: DataPlaceholderType;
	variant?: reportVariants;
};

function ComplexHighRisk({
	variant,
	data,
	dataPlaceholder,
}: {
	variant: reportVariants;
	data: AiReportType;
	dataPlaceholder?: DataPlaceholderType;
}) {
	if (hasErrorField(data.areas)) {
		return null;
	}

	if (variant === 'demo') {
		return (
			<LocationsDemo
				headerDescription="Areas that are known to be of higher risk and may require additional caution:"
				icon="dangerous"
				dataDemo={data.areas.high_risk_locations.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.highRisk}
			/>
		);
	}
	return data ? (
		<HighRisk data={data.areas.high_risk_locations} helpMessage={data.areas.help_message_risk} />
	) : null;
}

function ComplexSafestPlaces({
	variant,
	data,
	dataPlaceholder,
}: {
	variant: reportVariants;
	data: AiReportType;
	dataPlaceholder?: DataPlaceholderType;
}) {
	if (hasErrorField(data.areas)) {
		return null;
	}

	if (variant === 'demo') {
		return (
			<LocationsDemo
				headerDescription="Areas that are generally considered safe, relative to the area, and have lower reported incidents of crime:"
				icon="encrypted"
				dataDemo={data?.areas.safest_areas.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.safestPlaces}
			/>
		);
	}
	return data ? (
		<SafestPlaces
			data={data.areas.safest_areas}
			helpMessage={!data.areas.safest_areas.length ? data.areas.help_message_safest : ''}
		/>
	) : null;
}

function ComplexHospitals({
	variant,
	data,
	dataDemo,
	dataPlaceholder,
}: {
	variant: reportVariants;
	data?: AiReportType;
	dataDemo?: AiReportType;
	dataPlaceholder?: DataPlaceholderType;
}) {
	if (variant === 'demo') {
		return (
			<LocationsDemo
				headerDescription="Recommended hospitals that are more likely to offer 24-hour emergency/critical care and English-speaking staff. It is strongly advised to confirm these options prior to or immediately upon arrival with your local hosts or embassy:"
				icon="emergency"
				dataDemo={dataDemo?.hospitals.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.hospitals}
			/>
		);
	}
	return data ? <Hospitals data={data.hospitals} /> : null;
}

export default function Locations({ className, data, dataPlaceholder, variant }: LocationsType) {
	if (hasErrorField(data.areas)) {
		return <FailedToGenerateWarningSection />;
	}

	const tabData = [
		{
			label: 'High Risk Areas',
			id: 'high-risk-areas',
			icon: <span className="material-symbols-rounded color-dangerous">dangerous</span>,
			content: <ComplexHighRisk variant={variant!} data={data} dataPlaceholder={dataPlaceholder} />,
		},
		{
			label: 'Safest Places',
			id: 'safest-places',
			icon: <span className="material-symbols-rounded color-encrypted">encrypted</span>,
			content: (
				<ComplexSafestPlaces variant={variant!} data={data} dataPlaceholder={dataPlaceholder} />
			),
		},
		{
			label: 'Hospitals',
			id: 'hospitals',
			icon: <span className="material-symbols-rounded color-emergency">emergency</span>,
			content: (
				<ComplexHospitals variant={variant!} data={data} dataPlaceholder={dataPlaceholder} />
			),
		},
	];

	return (
		<section className={cn(styles.locations, className)}>
			<Tabs tabs={tabData} initialTab="high-risk-areas" />
		</section>
	);
}
