import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { OTPInput, SlotProps } from 'input-otp';

import { ROUTE } from '../../../App';
import { TextField } from '../../../components';
import Button from '../../../components/Buttons/Button';
import { useWizard } from '../../../components/Wizard';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';
import Header from '../Header';
import StepHeader from '../StepHeader';

import styles from '../GetStartedNew.module.scss';

type SignUpStep1Type = {
	step?: number;
	email: string;
	setTmpApiToken: (token: any) => void;
};

function Slot(props: SlotProps) {
	return (
		<div className={cn('slot', { active: props.isActive || !!props.char })}>
			<div>{props.char ?? props.placeholderChar}</div>
			{props.hasFakeCaret && <FakeCaret />}
		</div>
	);
}

function FakeCaret() {
	return (
		<div className="fake-caret">
			<div className="fake-caret-elm" />
		</div>
	);
}

export default function SignUpStep1(props: SignUpStep1Type) {
	const [code, setCode] = useState('');
	const [status, setStatus] = useState('');
	const { nextStep } = useWizard();
	const [passwordHelpText, setPasswordHelpText] = useState({ typeText: 'field-error', text: '' });

	const APIconfirmCode = () => {
		api.confirmEmailCode({ email: props.email, code }).then((response) => {
			const resStatus = response?.data?.status ?? '';
			const token = response?.data?.token ?? '';

			if (!response.ok) {
				setPasswordHelpText((state) => ({
					...state,
					typeText: 'field-error',
					text: response?.data?.message || 'Something went wrong, please try again',
				}));
			} else if (resStatus === 'password_required') {
				nextStep();
				props.setTmpApiToken(token);
			} else {
				setStatus(resStatus);
			}
		});
	};

	const APIsendConfirmCode = () => {
		api.sendConfirmCodeEmail({ email: props.email }).then((response) => {
			const resStatus = response?.data?.status ?? '';

			if (resStatus === 'ready') {
				nextStep();
			}
		});
	};

	const handleSubmit = () => {
		if (code.length === 6) {
			APIconfirmCode();
		}
	};

	if (status === 'ready') return <Redirect to={ROUTE.dashboard} />;

	return (
		<>
			<StepHeader step={props.step} />
			<div className="get-register-steps-container block-login">
				<div className="form-sign-in">
					<Header
						title="Confirm Email"
						description="We’ve sent a confirmation code to your email. Please enter the code below to verify your email address and start enjoying all the benefits of our service."
					/>
					<div className="form-container form-auth-container">
						<div className={styles['form-inner']}>
							<div className={styles['field-wrap']}>
								<TextField label="Enter your email" value={props.email} disabled />
							</div>
							<div className={cn(styles['field-wrap'], 'otp-wrapper')}>
								<OTPInput
									onChange={(data) => {
										setPasswordHelpText((state) => ({ ...state, text: '' }));
										setCode(data);
									}}
									maxLength={6}
									containerClassName="otp-container"
									render={({ slots }) => (
										<div className="flex">
											{slots.map((slot, idx) => (
												<Slot key={idx} {...slot} />
											))}
										</div>
									)}
								/>
								<a href="#" className={cn('resend-btn')} onClick={() => APIsendConfirmCode()}>
									Resend the code
								</a>
								{passwordHelpText.text && (
									<p className={cn(styles['field-help-text'], styles[passwordHelpText.typeText])}>
										{passwordHelpText.text}
									</p>
								)}
							</div>
							<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
								<Button
									className={cn(
										styles['button'],
										styles['variant-filled'],
										styles['color-primary'],
									)}
									disabled={code.length !== 6}
									variant="filled"
									color="primary"
									onClick={() => {
										handleSubmit();
									}}
								>
									Submit
								</Button>
							</div>
						</div>
					</div>
					<p className="text-terms">
						Already have an account? <Link to={ROUTE.signIn}>Sign in</Link>
					</p>
				</div>
			</div>
		</>
	);
}
