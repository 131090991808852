import { SummaryType } from '../../../../api/types/apiGetReport';
import Tabs from '../../components/ui/Tabs/Tabs';
import FailedToGenerateWarningSection from '../../RiskBriefReport/FailedToGenerateWarningSection';
import { hasErrorField } from '../../RiskBriefReport/utils';

import styles from './SummaryRiskBriefDemo.module.scss';

type SummaryTabProps = {
	title: string;
	body: React.ReactNode;
};

function SummaryTab({ title, body }: SummaryTabProps) {
	return (
		<div className={styles['summary-tab']}>
			<h2 className={styles.header}>{title}</h2>
			<div className={styles['body-section']}>{body}</div>
		</div>
	);
}

type SummaryRiskBriefDemoType = {
	summary: SummaryType;
};

export default function SummaryRiskBriefDemo(props: SummaryRiskBriefDemoType) {
	const { summary } = props;

	if (hasErrorField(summary)) {
		return <FailedToGenerateWarningSection />;
	}

	const { crime_and_security: crimeAndSecurity, health_and_safety: healthAndSafety } = summary.body;

	const crimeAndSecurityContent = (
		<>
			<p>{crimeAndSecurity[0]?.content}</p>
			<p className={styles['text-placeholder']}>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi aliquid molestiae nesciunt,
				cumque recusandae error. Voluptates atque necessitatibus quis maiores dolores aliquid
				accusamus tenetur! Porro adipisci placeat excepturi voluptatum libero quas ipsum saepe in
				numquam officia. Quos molestias eveniet sed, corporis vitae harum provident assumenda
				pariatur officia non, cumque cum saepe modi? Enim laborum saepe eius dolorem iure quisquam
				modi consequuntur praesentium facilis nesciunt sapiente, animi labore illum, nostrum
				laboriosam quaerat distinctio, assumenda officiis fuga. Similique adipisci non doloribus,
				natus blanditiis at et nemo placeat quas quod voluptates ut vero!
			</p>
		</>
	);

	const healthAndSafetyContent = (
		<>
			<p>{healthAndSafety[0]?.content}</p>
			<p className={styles['text-placeholder']}>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto eligendi culpa enim harum,
				aliquam dolor quisquam animi vero nam mollitia expedita consectetur ea saepe blanditiis
				reiciendis, temporibus a nostrum ipsam quam tempore error ipsa quasi esse sapiente.
				Asperiores, cupiditate obcaecati voluptates perferendis esse eveniet sit quis rem possimus
				quo. Ab.
			</p>
		</>
	);

	const topRecommendationContent = (
		<p className={styles['text-placeholder']}>
			1. Lorem ipsum dolor sit amet consectetur adipisicing elit.
			<br />
			2. Iusto eligendi culpa enim harum, qw aliquam dolor quisquam animi vero nam mollitia expedita
			consectetur re ea saepe blanditiis reiciendis, temporibus a nostrum ipsam quam tempore error
			ipsa quasi esse sapiente.
			<br />
			3. Asperiores, cupiditate obcaecati voluptates perferendis esse eveniet sit quis rem possimus
			quo. Ab. 4. Lorem ipsum dolor sit amet consectetur adipisicing elit.
			<br />
			5. Iusto se cupiditate eligendi culpa enim harum, aliquam dolor quisquam animi vero nam
			mollitia expedita consectetur ea saepe cupiditate blanditiis reiciendis, temporibus a nostrum
			ipsam quam tempore error ipsa quasi esse sapiente.
			<br />
		</p>
	);

	const tabData = [
		{
			label: 'BLUF summary',
			id: 'summary-bluf',
			content: <SummaryTab title={summary.bluf.header} body={<div>{summary.bluf.content}</div>} />,
		},
		{
			label: 'Crime and Security',
			id: 'crime-and-security',
			content: <SummaryTab title="Crime and Security" body={crimeAndSecurityContent} />,
		},
		{
			label: 'Health and Safety',
			id: 'health-and-safety',
			content: <SummaryTab title="Health and Safety" body={healthAndSafetyContent} />,
		},
		{
			label: 'Top Recommendations',
			id: 'top-recommendations',
			content: <SummaryTab title="Top Recommendations" body={topRecommendationContent} />,
		},
	];

	return <Tabs tabs={tabData} initialTab="summary-bluf" />;
}
