import { useContext } from 'react';

import { Context, Loader } from '../../components';

import GetStarted from './GetStartedNew';

function GetStartedStepsLoader(props: JSX.IntrinsicAttributes) {
	const { isLoader } = useContext(Context);

	if (isLoader) {
		return <Loader />;
	}
	return <GetStarted {...props} />;
}

export default GetStartedStepsLoader;
