import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context, TextField } from '../../components';
import Button from '../../components/Buttons/Button';
import { localStore, validateEmail } from '../../lib';
import cn from '../../lib/classNames';
import { api } from '../../sagas';

import FormHeader from './FormHeader';

import styles from './GetStartedNew.module.scss';


type SignInAuthType = {
	onForgot: (isForgot: boolean) => void;
	displayForgot?: boolean;
};

export default function SignInAuth(props: SignInAuthType) {
	const { onForgot, displayForgot } = props;

	const [emailValue, setEmailValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [isApiEmail, setIsApiEmail] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);
	const [passwordHelpText, setPasswordHelpText] = useState({ typeText: 'field-error', text: '' });
	const [isEmptyEmail, setIsEmptyEmail] = useState(true);
	const [renderDisplay, setRenderDisplay] = useState('');

	const refEmail = useRef<HTMLInputElement>();

	const refPassword = useRef<HTMLInputElement>();

	const history = useHistory();

	const { setUserProfileData, googleToken, firstStepDataExist, isLogged } = useContext(Context);

	const APIauthToken = () => {
		api.authToken({ name: emailValue, password: passwordValue }).then((response) => {
			if (!response.ok) {
				setPasswordHelpText((state) => ({
					...state,
					typeText: 'field-error',
					text: response.data.message,
				}));
			} else if (response.headers) {
				const apiToken = response.headers.authorization;
				api.apiInstance.setHeader('Authorization', apiToken);
				localStore.setToken(apiToken);
				localStore.setLoggedAs('SE');

				api.getProfile(googleToken).then((profileResponse) => {
					if (profileResponse.ok) {
						setUserProfileData({ ...profileResponse.data });

						history.push(ROUTE.signUp);
					}
				});
			}
		});
	};

	const emailVerification = () => {
		if (refEmail.current) {
			const email = refEmail.current.value;
			const result = validateEmail.validate({ email }).error;
			setEmailValue(email);
			setIsEmailError(!!result);
		}

		return null;
	};

	const getStarted = () => {
		APIauthToken();
	};

	const createAccount = () => {
		APIauthToken();
	};

	const handleSendForgot = (data: string) => {
		emailVerification();
		if (!isEmailError) {
			api.passwordForgot(data).then((response) => {
				if (response.ok) {
					setRenderDisplay('renderThank');
				} else {
					setRenderDisplay('renderErrorEmail');
				}
			});
		}
	};

	const renderPasswordHelpText = () => {
		setPasswordHelpText((state) => ({ ...state, text: '' }));
	};

	const handleSubmit = () => {
		if (!passwordValue) {
			setPasswordHelpText((state) => ({
				...state,
				typeText: 'field-error',
				text: 'Enter password',
			}));
		} else if (emailValue && !isEmailError) {
			isApiEmail ? getStarted() : createAccount();
		} else {
			emailVerification();
		}
	};

	useEffect(() => {
		renderPasswordHelpText();
	}, [isEmptyEmail, isApiEmail, passwordValue]);

	const handleKeyPress = (event: { key: string }) => {
		if (event.key === 'Enter' && !isEmptyEmail) {
			handleSubmit();
		}
	};

	const renderAuth = () => {
		return (
			<div className={styles['form-inner']}>
				<div className={styles['field-wrap']}>
					<TextField
						inputRef={refEmail}
						label="Your email"
						onBlur={() => {
							emailVerification();
							setIsEmptyEmail(!refEmail.current?.value);
						}}
						onChange={() => {
							setIsEmailError(false);
						}}
					/>
					{isEmailError && <p className={styles['field-error']}>Enter a valid email</p>}
				</div>
				<div className={cn(styles['field-wrap'], styles['field-wrap-password'])}>
					<TextField
						inputRef={refPassword}
						label="Password"
						type="password"
						value={passwordValue}
						onChange={() => {
							setPasswordHelpText((state) => ({ ...state, text: '' }));
							setPasswordValue(refPassword.current ? refPassword.current.value : '');
						}}
						onFocus={() => renderPasswordHelpText()}
						onKeyPress={handleKeyPress}
					/>

					{passwordHelpText.text && (
						<p className={cn(styles['field-help-text'], styles[passwordHelpText.typeText])}>
							{passwordHelpText.text}
						</p>
					)}

					<div className={cn(styles['field-wrap'], styles['field-wrap-link-forgot'])}>
						<span onClick={() => onForgot(true)}>Forgot Password?</span>
					</div>
				</div>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						className={cn(styles['button'], styles['variant-filled'], styles['color-primary'])}
						disabled={isEmptyEmail}
						variant="filled"
						color="primary"
						onClick={() => {
							handleSubmit();
						}}
					>
						SIGN IN
					</Button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (refEmail.current) {
			setEmailValue(refEmail.current.value);
			setIsEmptyEmail(!refEmail.current.value);
			refEmail.current.value !== '' && emailVerification();
		}
	}, [refEmail.current?.value]);

	useEffect(() => {
		if (isLogged && !firstStepDataExist) {
			// redirect to data form
			history.push(ROUTE.signUp);
		} else if (isLogged) {
			// redirect to dashboard
			history.push(ROUTE.dashboard);
		}
	}, [firstStepDataExist, isLogged]);

	const renderForgot = () => {
		if (renderDisplay === 'renderThank') {
			return renderThank();
		}
		if (renderDisplay === 'renderErrorEmail') {
			return renderErrorEmail();
		}

		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Forgot Password"
					description="Please enter your email and we'll send you a temporary password. You can use it to enter	your account."
				/>
				<div className={styles['form-container']}>
					<div className={styles['field-wrap']}>
						<TextField
							inputRef={refEmail}
							label="Your email"
							onBlur={() => emailVerification()}
							onChange={() => {
								setIsEmailError(false);
								setIsEmptyEmail(!refEmail.current?.value);
							}}
						/>
						{isEmailError && <p className={styles['field-error']}>Enter a valid email</p>}
					</div>

					<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
						<Button
							className={cn(styles['button'], styles['variant-filled'], styles['color-primary'])}
							disabled={isEmptyEmail}
							variant="filled"
							color="primary"
							onClick={() => {
								handleSendForgot(emailValue);
							}}
						>
							SEND PASSWORD
						</Button>
						<Button
							className={cn(styles['button'], styles['btn-cancel'])}
							variant="filled"
							color="primary"
							onClick={() => onForgot(false)}
						>
							CANCEL
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const renderThank = () => {
		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Success"
					description="We've sent the temporary password to your e-mail. We recommend you to go to your profile
					settings and set your new private password."
				/>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						variant="filled"
						color="primary"
						onClick={() => {
							onForgot(false);
						}}
					>
						BACK TO LOG IN
					</Button>
				</div>
			</div>
		);
	};

	const renderErrorEmail = () => {
		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Ooops!"
					description="We couldn't find this email in our data base. Please check if you entered a correct
					email."
				/>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						className={cn(styles['button'], styles['variant-filled'], styles['color-primary'])}
						variant="filled"
						color="primary"
						onClick={() => {
							setRenderDisplay('');
						}}
					>
						TRY AGAIN
					</Button>

					<Button
						className={cn(styles['button'], styles['variant-filled'], styles['color-primary'])}
						variant="filled"
						color="primary"
						onClick={() => onForgot(false)}
					>
						CANCEL
					</Button>
				</div>
			</div>
		);
	};

	const renderForm = () => {
		if (displayForgot) {
			return renderForgot();
		}
		return renderAuth();
	};

	return renderForm();
}
