import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context } from '../../components';
import { Wizard } from '../../components/Wizard';
import SignUpEmailStep0 from '../GetStarted/Steps/SignUpEmailStep0';
import SignUpEmailStep1 from '../GetStarted/Steps/SignUpEmailStep1';
import Layout from '../Layout/LayoutProfile';

import '../GetStarted/GetStartedNew.module.scss';

function ProfileChangeEmail() {
	const [email, setEmail] = useState('');
	const history = useHistory();

	const { userProfileData, setUserProfileData } = useContext(Context);

	return (
		<Layout>
			<Wizard>
				<SignUpEmailStep0 isPassChange handleSetEmail={setEmail} />
				<SignUpEmailStep1
					isPassChange
					setTmpApiToken={() => {}}
					email={email}
					onSuccess={() => {
						setUserProfileData({ ...userProfileData, email });
						history.push(ROUTE.profileManagement);
					}}
				/>
			</Wizard>
		</Layout>
	);
}

export default ProfileChangeEmail;
