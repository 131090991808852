import React, { useCallback, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, MoreVertical, Search } from 'lucide-react';
import moment from 'moment';

import styles from './UserTable.module.scss';
import cn from '../../../lib/classNames';

export interface User {
	name: string;
	email: string;
	group: string;
	createdAt: string;
	status: string;
	reportsCount: number;
	lastReportDate: string;
}

export interface Sort {
	unsorted: boolean;
	sorted: boolean;
}

export interface Pageable {
	sort: Sort;
	pageSize: number;
	pageNumber: number;
	offset: number;
	paged: boolean;
	unpaged: boolean;
}

export interface UsersResponse {
	content: User[];
	pageable: Pageable;
	last: boolean;
	totalElements: number;
	totalPages: number;
	sort: Sort;
	first: boolean;
	numberOfElements: number;
	size: number;
	number: number;
}

export interface UsersRequest {
	page?: number;
	size?: number;
	sort?: string;
	nameQuery?: string;
}

export const ITEMS_PER_PAGE = 10;
const PAGINATION_NEIGHBOURS = 2;

export interface UserTableType {
	data?: UsersResponse;
	handleFetch?: (params: UsersRequest) => void;
}

export function UserTable(props: UserTableType) {
	const [params, setParams] = useState<UsersRequest>({
		page: 1,
		size: ITEMS_PER_PAGE,
		sort: 'createdAt',
		nameQuery: '',
	});

	const currentPage = params?.page || 0;
	const items = props?.data?.content || [];

	const totalPages = props.data?.totalPages || 0;

	if (currentPage > totalPages && totalPages > 0) {
		setParams((state) => ({
			...state,
			page: totalPages,
		}));
	} else if (currentPage < 1 && totalPages > 0) {
		setParams((state) => ({
			...state,
			page: 1,
		}));
	} else if (totalPages === 0 && currentPage !== 1) {
		// reset to 1 if no results
		setParams((state) => ({
			...state,
			page: 1,
		}));
	}

	useEffect(() => {
		if (props.handleFetch) props.handleFetch(params);
	}, []);

	useEffect(() => {
		if (props.handleFetch) props.handleFetch(params);
	}, [params]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setParams((state) => ({
			...state,
			page: 1,
			nameQuery: event.target.value,
		}));
	};

	const handleActionClick = (email: string) => {
		// TODO
	};

	const goToPage = useCallback(
		(page: number) => {
			const pageNumber = Math.max(1, Math.min(page, totalPages));
			setParams((state) => ({
				...state,
				page: pageNumber,
			}));
		},
		[totalPages],
	);

	const goToPreviousPage = useCallback(() => {
		goToPage(currentPage - 1);
	}, [currentPage, goToPage]);

	const goToNextPage = useCallback(() => {
		goToPage(currentPage + 1);
	}, [currentPage, goToPage]);

	const getPaginationItems = () => {
		const totalNumbers = PAGINATION_NEIGHBOURS * 2 + 3; // current + neighbours + first + last + 2 ellipses
		const totalBlocks = totalNumbers + 2; // including ellipses potentially

		if (totalPages <= totalBlocks) {
			// show all pages if total pages is small
			return Array.from({ length: totalPages }, (_, i) => i + 1);
		}

		const startPage = Math.max(2, currentPage - PAGINATION_NEIGHBOURS);
		const endPage = Math.min(totalPages - 1, currentPage + PAGINATION_NEIGHBOURS);

		const pages: (number | string)[] = [1];

		// ellipsis before start page if needed
		if (startPage > 2) {
			pages.push('...');
		}

		// pages around the current page
		for (let i = startPage; i <= endPage; i++) {
			pages.push(i);
		}

		// ellipsis after end page if needed
		if (endPage < totalPages - 1) {
			pages.push('...');
		}

		// always show last page
		pages.push(totalPages);

		return pages;
	};

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<div className={styles.searchContainer}>
						<Search size={18} className={styles.searchIcon} />
						<input
							type="search"
							placeholder="Search by name..."
							value={params.nameQuery}
							onChange={handleSearchChange}
							className={styles.searchInput}
						/>
					</div>
				</div>

				<div className={styles.tableWrapper}>
					<table className={styles.userTable}>
						<thead>
							<tr>
								<th>Member name</th>
								<th className={styles.email}>Email</th>
								<th className={styles.center}>Role</th>
								<th className={styles.center}>Date added</th>
								<th className={styles.center}>Last active</th>
								<th className={styles.center}>Status</th>
								<th>Reports generated</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{items.length > 0 ? (
								items.map((user) => (
									<tr key={`${user.email}`}>
										<td>{user.name}</td>
										<td className={cn(styles.email, styles.center)}>
											<a href={`email:${user.email}`}>{user.email}</a>
										</td>
										<td className={cn(styles.role, styles.center)}>{user.group}</td>
										<td className={styles.center}>{moment(user.createdAt).format('DD.MM.YYYY')}</td>
										<td className={styles.center}>
											{user.lastReportDate ? moment(user.lastReportDate).format('DD.MM.YYYY') : ''}
										</td>
										<td className={styles.center}>
											<span
												className={`${styles.status} ${
													user.status === 'ready' ? styles.statusActive : styles.statusDisable
												}`}
											>
												{user.status}
											</span>
										</td>
										<td className={styles.reports}>{user.reportsCount}</td>
										<td>
											<button
												type="button"
												className={styles.actionButton}
												onClick={() => handleActionClick(user.email)}
												aria-label={`Actions for ${user.name}`}
											>
												<MoreVertical size={18} className={styles.actionIcon} />
											</button>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={7} className={styles.noResults}>
										No members found{params.nameQuery && ` matching "${params.nameQuery}"`}.
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{totalPages > 1 && (
				<div className={styles.pagination}>
					<button
						type="button"
						onClick={goToPreviousPage}
						disabled={currentPage === 1}
						className={styles.pageButton}
						aria-label="Go to previous page"
					>
						<ChevronLeft size={16} />
					</button>

					{getPaginationItems().map((item, index) =>
						typeof item === 'number' ? (
							<button
								type="button"
								key={`page-${item}`}
								onClick={() => goToPage(item)}
								className={`${styles.pageNumber} ${currentPage === item ? styles.currentPage : ''}`}
								aria-current={currentPage === item ? 'page' : undefined}
							>
								{item}
							</button>
						) : (
							<span key={`ellipsis-${index}`} className={styles.ellipsis}>
								{item}
							</span>
						),
					)}

					<button
						type="button"
						onClick={goToNextPage}
						disabled={currentPage === totalPages}
						className={styles.pageButton}
						aria-label="Go to next page"
					>
						<ChevronRight size={16} />
					</button>
				</div>
			)}
		</div>
	);
}
