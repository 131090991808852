import { useState } from 'react';
import cn from 'classnames';

import styles from './Tabs.module.scss';

type Tab = {
	id: string;
	label: string;
	icon?: JSX.Element | string;
	content: JSX.Element | string;
};

type TabsProps = {
	tabs: Tab[];
	initialTab?: string;
};

function Tabs(props: TabsProps) {
	const { tabs, initialTab } = props;
	const [activeTab, setActiveTab] = useState<string>(initialTab || tabs[0].id);

	return (
		<div className={styles['tabs-container']}>
			<div className={styles['tabs-header']}>
				{tabs.map((tab) => (
					<button
						type="button"
						key={tab.id}
						className={cn(styles['tab-button'], { [styles.active]: activeTab === tab.id })}
						onClick={() => setActiveTab(tab.id)}
					>
						{tab.icon && (
							<span className={styles['btn-icon']}>
								{typeof tab.icon === 'string' ? (
									<span className="material-symbols-rounded">{tab.icon}</span>
								) : (
									tab.icon
								)}
							</span>
						)}
						<span className={styles['btn-text']}>{tab.label}</span>
					</button>
				))}
			</div>
			<div className={cn(styles['tab-content'], styles.rounded)}>
				{tabs.find((tab) => tab.id === activeTab)?.content}
			</div>
		</div>
	);
}

export default Tabs;
