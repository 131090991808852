import { ROUTE } from '../../App';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';

import SectionHeader from './SectionHeader';

import styles from './ImproveSafeEsteem.module.scss';

type ImproveSafeEsteemType = {
	classPageContent?: string;
};

export default function ImproveSafeEsteem(props: ImproveSafeEsteemType) {
	const { classPageContent } = props;

	return (
		<section className={styles['improve-safe-esteem']}>
			<div className={cn(styles.content, classPageContent)}>
				<SectionHeader
					className={styles.header}
					title="Improve Your Safe-Esteem And Reclaim Your Life Journey Today!"
					textAlign="center"
				/>
				<div className={styles['btn-wrap']}>
					<Button
						variant="filled"
						elementType="navigate"
						color="secondary"
						href={ROUTE.signIn}
						size="large"
						sizeWidth="full"
					>
						Get started
					</Button>
				</div>
			</div>
		</section>
	);
}
