import { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import cn from '../lib/classNames';

import { TooltipLGBTQI } from './Tooltips';

import styles from './InfoLGBTQI.module.scss';

type InfoLGBTQIType = {
	smallIcon?: boolean;
	isMobile?: boolean;
};

export default function InfoLGBTQI(props: InfoLGBTQIType) {
	const { smallIcon = false, isMobile = false } = props;

	const [tipActive, setTipActive] = useState('');

	const descriptionBold = (
		<b className="tooltip-description-bold">Why are only male/female options available? </b>
	);
	const description = (
		<span className="tooltip-description">
			Gender is much more complex than a simple binary and can vary greatly across different
			cultures and societies. Our goal is to provide the world’s most accurate and data-driven risk
			models, and we are actively working to increase personalization factors and specificity in our
			estimates. Unfortunately, current health, accident, and criminal victimization data are
			primarily categorized by sex at birth. As for race and ethnicity, gender identity can have a
			significant impact on basic (age/sex) incident rates. Yet, these deviations tend to lose value
			for relative comparisons or estimates in an international and travel context - which are
			central to Safe-xplore risk ratings and reports. To help address this issue, we encourage
			LGBTQI+ travelers to select the sex at birth - which will hold the greater predictive value -
			and to use our dedicated widget to assess the supportive or adverse environment of their
			travel destination.
		</span>
	);
	const toolTipId = 'LGBTQI';

	return (
		<ClickAwayListener onClickAway={() => setTipActive('')}>
			<div className={cn(styles.InfoLGBTQI, 'info-lgbtqi')}>
				<TooltipLGBTQI
					id={`tooltip-${toolTipId}`}
					open={tipActive === toolTipId}
					arrow
					maxWidth={isMobile ? 'none' : '400'}
					placement="bottom"
					isMobile={isMobile}
					title={
						<div className="tooltip-text">
							{descriptionBold}
							{description}
							<p
								className="link tooltip-link"
								onClick={() => {
									setTipActive('');
								}}
							>
								CLOSE
							</p>
						</div>
					}
				>
					<div
						className={styles['block-info-icon']}
						onClick={() => {
							setTipActive(toolTipId);
						}}
					>
						<span
							className={cn(
								styles['icon-info'],
								{ [styles['small-icon']]: smallIcon },
								'icons-animation',
								'material-icons',
							)}
						>
							info_outline
						</span>
					</div>
				</TooltipLGBTQI>
			</div>
		</ClickAwayListener>
	);
}
