import { useState } from 'react';

import Layout from '../Layout/LayoutProfile';
import ReportHistory from '../Report/ReportHistory/ReportHistory';

import styles from './ProfileHistory.module.scss';

function ProfileHistory() {
	const [logout, setLogout] = useState(false);
	return (
		<Layout isLogout={logout}>
			<div className={styles['profile-history-wrap']}>
				<h1 className={styles.header}>Reports history</h1>
				<div className={styles.body}>
					<ReportHistory
						title="Your Reports"
						subTitle="Here’s the full history of reports associated with your account."
						className={styles['report-history']}
					/>
				</div>
			</div>
		</Layout>
	);
}

export default ProfileHistory;
