import { useEffect } from 'react';

export { default as InfoOutlinedIcon } from '@material-ui/icons/InfoOutlined';
export { default as Slider } from './Slider';
export { default as RiskSlider } from './RiskSlider';
export { default as ButtonWhite } from './ButtonWhite';
export { default as ButtonClose } from './ButtonClose';
export { default as Logotype } from './Logotype';
export { default as SliderLine } from './SliderLine';
export { default as Download } from './Download';
export { default as GraphColor } from './GraphColor';
export { default as RelativeRiskChartV2 } from './RelativeRiskChartV2';
export { default as Map } from './Map';
export { default as SelectWithButtons } from './SelectWithButtons';
export { default as Select } from './Select';
export { default as RiskTrends } from './RiskTrends';
export { default as RiskRatingChart } from './RiskRatingChart';
export { default as World } from './World';
export { default as Autocomplete } from './Autocomplete';
export { default as SelectLocation } from './SelectLocation';
export { default as Input } from './Input';
export { default as TextField } from './TextField';
export { default as Loader } from './Loader';
export { default as GoogleReCaptcha } from './GoogleReCaptcha';
export { default as GoogleAnalytics } from './GoogleAnalytics';
export { TooltipFullWidth, TooltipWidth220 } from './Tooltips';
export { default as ButtonGoogleAuth } from './ButtonGoogleAuth';
export { default as ButtonAppleAuth } from './ButtonAppleAuth';
export { default as SimilarCities } from './SimilarCities';
export { default as ModalCustom } from './ModalCustom';
export { default as ButtonLinkedGoogle } from './ButtonLinkedGoogle';
export { default as ButtonLinkedApple } from './ButtonLinkedApple';
export { default as PageTitle } from './PageTitle';
export { default as InputSlider } from './InputSlider';
export { default as HeaderNotAuth } from './HeaderNotAuth';
export { default as ContextProvider, Context } from './ContextProvider';
export { default as DataLayer } from './DataLayer';
export { default as AccordionCustom } from './AccordionCustom';
export { default as Icon } from './Icon';
export { default as TagsInput } from './TagsInput';

export function useOutsideClickEvent(ref, onClick) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				if (onClick) onClick(event);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, onClick]);
}
