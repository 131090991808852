/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ImageType } from 'react-images-uploading';

import { SquarePen } from 'lucide-react';
import avatarPlaceholder from '../../../images/profile-photo-placeholder.svg';
import { Context, GoogleReCaptcha, Select, SelectLocation, TextField } from '../../components';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { AGE_BUCKET, fakeAgeBucket, GENDERS_FOR_SELECT } from '../../lib/constats';
import { api } from '../../sagas';
import { LocationDataType } from '../../types/LocationData';
import Layout from '../Layout/LayoutProfile';

import { UploadFile } from '.';

import styles from './Profile.module.scss';


function Profile() {
	const [logout, setLogout] = useState(false);
	const [nameValue, setNameValue] = useState('');
	const [ageBucket, setAgeBucket] = useState('');
	const [gender, setGender] = useState('');
	const [place, setPlace] = useState<LocationDataType>();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);

	const {
		setGoogleToken,
		userLocation,
		userProfileData,
		setUserProfileData,
		userAvatar,
		googleToken,
	} = useContext(Context);

	const token = useRef('');

	const handleCaptchaVerify = useCallback((newToken: string) => {
		token.current = newToken;
		setGoogleToken(newToken);
	}, []);

	const onSelect = (selectedFile: ImageType) => {
		if (selectedFile.length) {
			const formData = new FormData();
			formData.append('file', selectedFile[0].file);
			api.uploadAvatar(formData).then((response) => {
				if (response.ok) {
					setUserProfileData({ ...userProfileData, seAvatar: response.data.avatarId });
				}
			});
		}
	};

	const onDelete = () => {
		api.deleteAvatar().then((response) => {
			if (response.ok) {
				setUserProfileData({ ...userProfileData, seAvatar: null });
			}
		});
	};

	const avatarURL = () => {
		if (userProfileData.seAvatar) {
			return userAvatar;
		}
		return avatarPlaceholder;
	};

	useEffect(() => {
		setAgeBucket(fakeAgeBucket(userProfileData.ageBucket));
		setGender(userProfileData.gender);
		setPlace(userLocation);
		setNameValue(userProfileData.seName);
	}, [
		userProfileData,
		userProfileData.ageBucket,
		userProfileData.gender,
		userProfileData.seName,
		userLocation,
	]);

	const reset = useCallback(() => {
		setAgeBucket(fakeAgeBucket(userProfileData.ageBucket));
		setGender(userProfileData.gender);
		setPlace(userLocation);
		setNameValue(userProfileData.seName);
		setIsEditMode(false);
	}, [
		userProfileData,
		userProfileData.ageBucket,
		userProfileData.gender,
		userProfileData.seName,
		userLocation,
	]);

	const handleSelectAge = useCallback((value: string) => {
		setAgeBucket(value);
	}, []);

	const handleSelectGender = useCallback((value: string) => {
		setGender(value);
	}, []);

	const handleSelectPlace = useCallback((newPlace: LocationDataType) => {
		setPlace(newPlace);
	}, []);

	const isUpdateData = () => {
		if (
			userProfileData &&
			userProfileData.ageBucket === ageBucket &&
			userProfileData.gender === gender &&
			userProfileData.name === place?.label &&
			userProfileData.seName === nameValue
		) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		isUpdateData();
	}, [ageBucket, gender, place, nameValue]);

	const handleSubmit = useCallback(() => {
		setIsSubmitted(true);
		const payloadData = {
			ageBucket: fakeAgeBucket(ageBucket),
			gender,
			locationId: place?.value?.id,
			locationName: place?.value?.name,
			locationLongitude: place?.value?.longitude,
			locationLatitude: place?.value?.latitude,
		};

		api.updateProfile({ ...payloadData, name: nameValue }, googleToken).then((response) => {
			if (response.ok) {
				setUserProfileData({
					...userProfileData,
					...payloadData,
					seName: nameValue,
				});
			}

			reset();
		});
	}, [ageBucket, gender, place, googleToken, nameValue]);

	return (
		<Layout isLogout={logout}>
			<div className={styles['profile-wrap']}>
				{!token.current && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
				<h1 className={styles.header}>Profile</h1>
				<div className={styles['form-wrap']}>
					<div className={styles['title-wrap']}>
						<h3 className={styles.title}>Account Information</h3>
						<span
							className={cn(styles['title-button'], { [styles.hide]: isEditMode })}
							onClick={() => setIsEditMode(!isEditMode)}
						>
							<SquarePen size={16} /> EDIT
						</span>
					</div>
					<h4 className={styles['sub-title']}>User personal data</h4>

					<div className={styles['avatar-wrap']}>
						<div className={styles.avatar} style={{ backgroundImage: `url("${avatarURL()}")` }} />

						<UploadFile
							maxFileSize={2000000}
							acceptType={['jpg', 'png', 'jpeg']}
							onSelect={onSelect}
						>
							<Button
								variant="filled"
								color="primary"
								sizeWidth="full"
								className={styles['avatar-upload-btn']}
							>
								Upload new picture
							</Button>
						</UploadFile>

						<a
							onClick={(e: any) => {
								onDelete();
								e.stopPropagation();
							}}
							className={cn(styles['avatar-delete-btn'], {
								[styles['avatar-disabled-delete-btn']]: isUpdateData(),
							})}
						>
							Delete picture
						</a>
					</div>
					<div className={styles.body}>
						<div className={styles['field-wrap']}>
							<div className={styles['field-inner']}>
								<TextField
									className="email-field"
									label="Full name"
									placeholder="Enter your full name"
									value={nameValue}
									onChange={setNameValue}
									disabled={!isEditMode}
								/>
								{isSubmitted && nameValue.length === 0 && (
									<p className={styles['field-error']}>Enter a valid name</p>
								)}
							</div>
						</div>

						<div className={cn(styles['field-wrap'], styles['field-wrap-location'])}>
							<div className={styles['field-inner']}>
								<div className={styles['field-label']}>Home city</div>
								<SelectLocation
									place={place}
									onSelect={handleSelectPlace}
									disabled={!isEditMode}
									withoutBorder
								/>
							</div>
						</div>

						<div className={styles['field-inline']}>
							<div className={cn(styles['field-wrap'], styles['field-wrap-gender'])}>
								<div className={styles['field-inner']}>
									<div className={styles['field-label']}>Gender</div>
									<Select
										style={{ width: '100%' }}
										value={gender}
										items={GENDERS_FOR_SELECT}
										onSelect={handleSelectGender}
										disabled={!isEditMode}
									/>
								</div>
							</div>

							<div className={cn(styles['field-wrap'], styles['field-wrap-age'])}>
								<div className={styles['field-inner']}>
									<div className={styles['field-label']}>Age group</div>
									<Select
										style={{ width: '100%' }}
										value={ageBucket}
										items={AGE_BUCKET}
										onSelect={handleSelectAge}
										disabled={!isEditMode}
									/>
								</div>
							</div>
						</div>
						<div className={cn(styles['field-inline'], { [styles.hide]: !isEditMode })}>
							<Button onClick={handleSubmit} variant="filled" color="primary" sizeWidth="full">
								SAVE
							</Button>

							<Button onClick={reset} variant="outlined" color="primary" sizeWidth="full">
								CANCEL
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Profile;
