import { ReactElement, useState } from 'react';

import cn from '../../lib/classNames';

import styles from './AlertInteractive.module.scss';

type AlertInteractiveType = {
	className?: string;
	type?: 'info' | 'warning' | 'errors' | 'success';
	icon?: string;
	title?: string;
	subTitle?: string;
	text?: string;
	closeButton?: boolean;
};

export default function AlertInteractive(props: AlertInteractiveType) {
	const { className, type = 'info', icon, title, subTitle, text, closeButton = false } = props;
	const [isVisible, setIsVisible] = useState(true);

	if (!isVisible) return null;

	return (
		<div className={cn(styles['alert-interactive'], styles[`type-${type}`], className)}>
			{icon && (
				<div className={cn(styles['icon-wrap'], 'icon-wrap')}>
					<span className={cn(styles.icon, 'icon', 'material-icons-round')}>{icon}</span>
				</div>
			)}
			<div className={cn(styles['text-wrap'], 'text-wrap')}>
				{title && <h3 className={cn(styles.title, 'title')}>{title}</h3>}
				{subTitle && <h5 className={cn(styles['sub-title'], 'sub-title')}>{subTitle}</h5>}
				{text && <p className={cn(styles.text, 'text')}>{text}</p>}
			</div>
			{closeButton && (
				<div className={styles['button-wrap']}>
					<button
						type="button"
						className={cn(styles['close-button'], 'material-icons-round')}
						onClick={() => setIsVisible(false)}
					>
						close
					</button>
				</div>
			)}
		</div>
	);
}
