import {
	Children,
	cloneElement,
	FC,
	memo,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { Handler, WizardProps } from './types';
import WizardContext from './wizardContext';

const Wizard: FC<PropsWithChildren<WizardProps>> = memo(
	({ header, footer, children, onStepChange, wrapper: Wrapper, startIndex = 0 }) => {
		const [activeStep, setActiveStep] = useState(startIndex);
		const [isLoading, setIsLoading] = useState(false);
		const hasNextStep = useRef(true);
		const hasPreviousStep = useRef(false);
		const nextStepHandler = useRef<Handler>(() => {});
		const stepCount = Children.toArray(children).length;

		hasNextStep.current = activeStep < stepCount - 1;
		hasPreviousStep.current = activeStep > 0;

		useEffect(() => {
			setActiveStep(startIndex);
		}, [startIndex]);

		const goToNextStep = useCallback(() => {
			if (hasNextStep.current) {
				const newActiveStepIndex = activeStep + 1;

				setActiveStep(newActiveStepIndex);
				onStepChange?.(newActiveStepIndex);
			}
		}, [activeStep, onStepChange]);

		const goToPreviousStep = useCallback(() => {
			if (hasPreviousStep.current) {
				nextStepHandler.current = null;
				const newActiveStepIndex = activeStep - 1;

				setActiveStep(newActiveStepIndex);
				onStepChange?.(newActiveStepIndex);
			}
		}, [activeStep, onStepChange]);

		const goToStep = useCallback(
			(stepIndex: number) => {
				if (stepIndex >= 0 && stepIndex < stepCount) {
					nextStepHandler.current = null;
					setActiveStep(stepIndex);
					onStepChange?.(stepIndex);
				}
			},
			[stepCount, onStepChange],
		);

		// Callback to attach the step handler
		const handleStep = useRef((handler: Handler) => {
			nextStepHandler.current = handler;
		});

		const doNextStep = useCallback(async () => {
			if (hasNextStep.current && nextStepHandler.current) {
				try {
					setIsLoading(true);
					await nextStepHandler.current();
					setIsLoading(false);
					nextStepHandler.current = null;
					goToNextStep();
				} catch (error) {
					setIsLoading(false);
					throw error;
				}
			} else {
				goToNextStep();
			}
		}, [goToNextStep]);

		const wizardValue = useMemo(
			() => ({
				nextStep: doNextStep,
				previousStep: goToPreviousStep,
				handleStep: handleStep.current,
				isLoading,
				activeStep,
				stepCount,
				isFirstStep: !hasPreviousStep.current,
				isLastStep: !hasNextStep.current,
				goToStep,
			}),
			[doNextStep, goToPreviousStep, isLoading, activeStep, stepCount, goToStep],
		);

		const activeStepContent = useMemo(() => {
			const reactChildren = Children.toArray(children);

			return reactChildren[activeStep];
		}, [activeStep, children, header, footer]);

		const enhancedActiveStepContent = useMemo(
			() => (Wrapper ? cloneElement(Wrapper, { children: activeStepContent }) : activeStepContent),
			[Wrapper, activeStepContent],
		);

		return (
			<WizardContext.Provider value={wizardValue}>
				{header}
				{enhancedActiveStepContent}
				{footer}
			</WizardContext.Provider>
		);
	},
);

export default Wizard;
