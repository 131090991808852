import { useContextNavigation } from '../../../components/ContextNavigation';
import cn from '../../../lib/classNames';
import { ViolentCrimeRisk } from '../../Dashboard';
import { Footer, Header } from '../components';

import styles from './ReportPreloader.module.scss';

export type PreloaderDataType = {
	home?: string;
	personName?: string;
	personAgeBucket?: string;
	personGender?: string;
};

type TravelRiskReportType = {
	className?: string;
	preloaderData?: PreloaderDataType;
	renderHeaderFooter?: boolean;
};

export default function ReportPreloader(props: TravelRiskReportType) {
	const { className, preloaderData = {}, renderHeaderFooter = false } = props;
	const { home, personName, personAgeBucket, personGender } = preloaderData;

	const { activePage } = useContextNavigation();

	return (
		<div className={cn(styles['preloader-report'], className)}>
			{renderHeaderFooter && (
				<section className={cn(styles.section, styles['header-wrap'])}>
					<Header exportToPDF={() => {}} isLoaderExportImg={false} />
				</section>
			)}

			<div className={cn(styles.content, 'content')}>
				<section className={styles.section}>
					{/* TODO: need to clarify h2/h3 */}
					{/* <h3>
						<span className={styles['preloader-text-block']}>Violent Crime Risk Index Classes</span>
					</h3> */}
					<h2>
						<span className={styles['preloader-text-block']}>Violent Crime Risk Index Classes</span>
					</h2>
					<h4>
						<span className={styles['preloader-text-block']}>
							Ranking according to the Violent Crime Risk (ViCRI) Index for cities and countries
						</span>
					</h4>
				</section>

				<section className={styles.section}>
					<ViolentCrimeRisk
						title="The Violent Crime Risk Index for Global Cities"
						subTitle="Your home and destination ranking of homicide, robbery, rape, and aggravated assault risks."
						showTooltipIcon
						showDownloadScreenIcon={false}
						isShowEstimate={false}
						isPreloader
					/>
				</section>

				<section
					className={cn(styles.section, styles['preloader-map'], styles['preloader-section'])}
				>
					<h3>
						<span className={styles['preloader-text-block']}>
							National Violent Crime Risk Index
						</span>
					</h3>
					<h4>
						<span className={styles['preloader-text-block']}>World</span>
					</h4>
				</section>

				{renderHeaderFooter && (
					<section className="section footer">
						<Footer />
					</section>
				)}
			</div>
		</div>
	);
}
