import { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context, GoogleReCaptcha, Loader } from '../../components';
import { Wizard } from '../../components/Wizard';
import LayoutStarted from '../Layout/LayoutStarted';

import SignUpStep0 from './Steps/SignUpStep0';
import SignUpStep1 from './Steps/SignUpStep1';
import SignUpStep2 from './Steps/SignUpStep2';
import SignUpStep3 from './Steps/SignUpStep3';
import SignUpStep4 from './Steps/SignUpStep4';
import SignUpEmailStep0 from './Steps/SignUpEmailStep0';
import SignUpEmailStep1 from './Steps/SignUpEmailStep1';

import './GetStartedNew.module.scss';

export type NameValueType = {
	name: string;
	value: string;
};

function GetRegisterNewSteps() {
	const [step, setStep] = useState<number | undefined>(); // default value = undefined!
	const [email, setEmail] = useState('');
	const [tmpApiToken, setTmpApiToken] = useState('');
	const [gotoDashboard, setGotoDashboard] = useState(false);

	const {
		userProfileData,
		googleToken,
		setGoogleToken,
		isLogged,
		firstStepDataExist,
		isLoader,
		isEmailRequired,
		isAppleAuth,
	} = useContext(Context);

	const handleNextStep = useCallback((nextStep: number) => {
		setStep(nextStep);
	}, []);

	const checkingSteps = () => {
		if (isLogged && firstStepDataExist) setGotoDashboard(true);

		if (!isLogged) {
			handleNextStep(0);
		} else if (isLogged) {
			if (isAppleAuth) {
				if (isEmailRequired) {
					handleNextStep(0);
				} else if (!firstStepDataExist) {
					handleNextStep(2);
				} else {
					setGotoDashboard(true);
				}
			} else if (!firstStepDataExist) {
				handleNextStep(3);
			} else {
				setGotoDashboard(true);
			}
		}
	};

	useEffect(() => {
		checkingSteps();
	}, [isLogged, userProfileData, isAppleAuth, isEmailRequired]);

	const handleCaptchaVerify = useCallback((newToken: string) => {
		setGoogleToken(newToken);
	}, []);

	if (gotoDashboard) return <Redirect to={ROUTE.dashboard} />;

	if (isLoader) return <Loader />;

	return (
		<LayoutStarted displayStoreButtons>
			{!googleToken && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}

			{isAppleAuth && (
				<Wizard startIndex={step}>
					<SignUpEmailStep0 isAppleAuth step={0} handleSetEmail={setEmail} />
					<SignUpEmailStep1
						isAppleAuth
						step={0}
						setTmpApiToken={(data) => setTmpApiToken(data)}
						email={email}
					/>
					<SignUpStep3 isAppleAuth step={1} />
					<SignUpStep4 />
				</Wizard>
			)}
			{!isAppleAuth && (
				<Wizard startIndex={step}>
					<SignUpStep0 step={0} handleSetEmail={(data) => setEmail(data)} />
					<SignUpStep1 step={0} setTmpApiToken={(data) => setTmpApiToken(data)} email={email} />
					<SignUpStep2 step={1} email={email} tmpApiToken={tmpApiToken} />
					<SignUpStep3 step={2} />
					<SignUpStep4 />
				</Wizard>
			)}
		</LayoutStarted>
	);
}

export default GetRegisterNewSteps;
