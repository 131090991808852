import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../../App';
import { Context } from '../../../components';
import Button from '../../../components/Buttons/Button';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';
import Header from '../Header';

import styles from '../GetStartedNew.module.scss';

type SignUpStep4Type = {};

export default function SignUpStep4(props: SignUpStep4Type) {
	const history = useHistory();
	const { setUserProfileData, googleToken } = useContext(Context);

	const handleClick = () => {
		api.getProfile(googleToken).then((profileResponse) => {
			if (profileResponse.ok) {
				setUserProfileData({ ...profileResponse.data });

				history.push(ROUTE.dashboard);
			}
		});
	};

	return (
		<div className="get-register-steps-container block-login">
			<div className="form-sign-in form-account-ready">
				<Header title="Your Safe-xplore Account Is Ready!" />
				<div className="form-container form-auth-container">
					<div className={styles['form-inner']}>
						<div className={styles['field-wrap']}>
							<p className="top-text">
								Welcome! You now have access to Safe-xplore's free features, including:
							</p>

							<ul className="list">
								<li>Homebase risks dashboard</li>
								<li>Unlimited free reports</li>
								<li>Report history tracking</li>
							</ul>

							<p className="button-text">
								Start using your free features today! If you need more, consider our corporate plan.
							</p>
						</div>

						<div className={cn(styles['form-inner'], styles['field-wrap-btn'])}>
							<Button
								className={cn(
									styles['button'],
									styles['variant-filled'],
									styles['color-secondary'],
								)}
								variant="filled"
								color="primary"
								onClick={handleClick}
							>
								Go to dashboard
							</Button>
						</div>

						<div className={cn(styles['form-inner'], styles['field-wrap-btn'], 'learn-more-btn')}>
							<Button
								className={cn(styles['button'], styles['variant-filled'], styles['color-primary'])}
								variant="outlined"
								color="primary"
								onClick={() => history.push(ROUTE.about)}
							>
								Learn About Corporate
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
