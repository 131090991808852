import cn from '../../lib/classNames';

import styles from './StepHeader.module.scss';

type StepHeaderType = {
	step?: number;
	skip?: number;
};

export default function StepHeader(props: StepHeaderType) {
	const { step = 0, skip } = props;

	const items = ['You email', 'Create password', 'About you'];

	const renderItems = items
		.filter((_, i) => i !== skip)
		.map((e, index) => {
			return (
				<div className={styles['step-header-item']} key={e}>
					<span
						className={cn(styles['step-header-number'], {
							[styles['step-header-selected-number']]: step === index || step > index,
						})}
					>
						{step <= index ? index + 1 : '✓'}
					</span>
					<span
						className={cn(styles['step-header-item-text'], {
							[styles['step-header-item-hide-text']]: step !== index,
						})}
					>
						{e}
					</span>
				</div>
			);
		});

	return <div className={styles['step-header']}>{renderItems}</div>;
}
