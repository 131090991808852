import cn from '../../../lib/classNames';

import styles from './FailedToGenerateWarningSection.module.scss';

const TITLE_DEFAULT = 'Failed to load data for this section.';
const SUBTITLE_DEFAULT = 'This may be due to a temporary server error or missing information.';

const icon = 'warning_amber';

export type FailedToGenerateWarningSectionType = {
	title?: string;
	subTitle?: string;
};

export default function FailedToGenerateWarningSection(props: FailedToGenerateWarningSectionType) {
	const { title = TITLE_DEFAULT, subTitle = SUBTITLE_DEFAULT } = props;

	return (
		<div className={cn(styles['failed-to-generate-section'], 'section-wrap-white')}>
			<div className={styles.wrapper}>
				<div className={styles['title-block']}>
					<div className={styles['icon-wrap']}>
						<span className={cn(styles.icon, 'icon', 'material-icons-round')}>{icon}</span>
					</div>
					<h3 className={styles.title}>{title}</h3>
				</div>
				<h5 className={styles.subtitle}>{subTitle}</h5>
			</div>
		</div>
	);
}
