import { useCallback, useContext } from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

import { Context } from './ContextProvider';
import { getGoogleUserDataFromCredential, userSetGoogleOAuthData } from './ContextProvider.helper';

import styles from './ButtonGoogleAuth.module.scss';

export default function ButtonGoogleAuth() {
	const { googleToken, setGoogleAuth, setUserProfileData } = useContext(Context);

	const onSuccess = useCallback(
		(response: CredentialResponse) => {
			const data = getGoogleUserDataFromCredential(response);
			setGoogleAuth(data);
			userSetGoogleOAuthData(googleToken, data, setUserProfileData);
		},
		[setGoogleAuth, googleToken],
	);

	return (
		<div className={styles['google-auth-btn']}>
			<GoogleLogin
				onSuccess={onSuccess}
				onError={() => {
					console.log('::: ButtonGoogleAuth >> : Login Failed');
				}}
				width={200}
				shape="circle"
			/>
		</div>
	);
}
