import { useContext } from 'react';

import { WizardValues } from './types';
import WizardContext from './wizardContext';

const useWizard = () => {
	const context = useContext(WizardContext);
	if (!context) {
		throw Error('Wrap your step with `Wizard`');
	} else {
		return context as WizardValues;
	}
};

export default useWizard;
