import { useContext } from 'react';

import { fakeAgeBucket } from '../../../api/adapter';
import { ROUTE } from '../../../App';
import { Context } from '../../../components';
import UserPlan from '../../../components/UserPlan/UserPlan';

import UserProfileDropdownActionRow from './UserProfileDropdownActionRow';

import styles from './UserProfileDropdown.module.scss';

type UserProfileDataRowType = {
	label: string;
	value?: string;
};

function UserProfileDataRow({ label, value }: UserProfileDataRowType) {
	if (!value) return null;
	return (
		<div className={styles.row}>
			<p className={styles.label}>{label}</p>
			<p className={styles.value}>{value}</p>
		</div>
	);
}

function UserProfileDropdown() {
	const { userLogout, userName, userGender, userProfileData, userAvatar, userLocation, isAdmin } =
		useContext(Context);

	const ageBucket = userProfileData?.ageBucket;
	const adapdedAgeBucket = ageBucket ? fakeAgeBucket(ageBucket) : '';

	// TODO: use real data when API is ready
	const planName = "You're using Free plan";
	const totalPlans = 50;
	const yourPlans = 25;

	const logoutHandler = () => {
		userLogout();
	};

	return (
		<div className={styles.container}>
			<div className={styles.avatar} style={{ backgroundImage: `url(${userAvatar})` }} />
			<h4 className={styles.name}>{userName}</h4>
			<div className={styles.details}>
				<UserProfileDataRow label="Home city" value={userLocation?.label} />
				<UserProfileDataRow label="Age group" value={`${adapdedAgeBucket} years old`} />
				<UserProfileDataRow label="Gender" value={userGender?.genderFullLabel} />
			</div>

			<div className={styles.plan}>
				{/* TODO: option 1 (test) */}
				{/* TODO: hide the component until the API is ready */}
				{/* <UserPlan planName={planName} /> */}
				{/* TODO: option 2 (test) */}
				{/* <UserPlan planName={planName} totalPlans={totalPlans} yourPlans={yourPlans} showUpgradeButton={false} /> */}
			</div>

			<div className={styles.actions}>
				<UserProfileDropdownActionRow label="Go to profile" link={ROUTE.profile} />
				{isAdmin && (
					<>
						<UserProfileDropdownActionRow label="Company information" link={ROUTE.company} />
						<UserProfileDropdownActionRow label="Company members" link={ROUTE.companyMembers} />
					</>
				)}
				<UserProfileDropdownActionRow label="Reports history" link={ROUTE.profileHistory} />
				<UserProfileDropdownActionRow label="Account management" link={ROUTE.profileManagement} />
				<div className={styles.divider} />
				<UserProfileDropdownActionRow label="Log out" onClick={logoutHandler} iconName="logout" />
			</div>
		</div>
	);
}

export default UserProfileDropdown;
