import { EdvisoriesType } from '../../../api/types/apiGetReport';
import Alert from '../../../components/Alert/Alert';

import FailedToGenerateWarningSection from './FailedToGenerateWarningSection';
import { hasErrorField } from './utils';

const ADVISORY_LEVELS = {
	advisory_levels_1: 1,
	advisory_levels_2: 2,
	advisory_levels_3: 3,
	advisory_levels_4: 4,
};

type AdvisoryAlertProps = {
	advisories: EdvisoriesType;
};

function AdvisoryAlert({ advisories }: AdvisoryAlertProps) {
	if (hasErrorField(advisories)) {
		return <FailedToGenerateWarningSection />;
	}

	if (
		!advisories ||
		advisories?.advisory_level <= ADVISORY_LEVELS?.advisory_levels_2 ||
		!advisories?.warning_details
	) {
		return null;
	}

	return (
		<Alert
			type="errors"
			title="ATTENTION: ONE OR MORE GOVERNMENTS ARE EITHER RESTRICTING OR ADVISING AGAINST TRAVEL TO THIS DESTINATION"
			text={advisories.warning_details}
		/>
	);
}

export default AdvisoryAlert;
