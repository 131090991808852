import apisauce from 'apisauce';

import Config from '../config';
import { localStore } from '../lib';

export const STATUS = {
	OK: 200,
	UNAUTHORIZED: 401,
	AGREEMENT_ERROR: 406,
};

export const MODIFIER_CODE = {
	Traveling: 'TRAVEL',
};

const v1 = '/api-public/covid19/v1';

const fileHeaders = {
	headers: { 'Content-Type': 'multipart/form-data', 'Cache-Control': 'no-cache' },
};

const toQueryString = (obj) =>
	obj && typeof obj === 'object'
		? Object.entries(obj)
				.map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
				.join('&')
		: '';

const create = () => {
	const config = {
		baseURL: Config.apiBaseURL,
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': 'application/json',
		},
		timeout: 120000,
	};
	const auth = localStore.getToken();
	if (auth) {
		config.headers.Authorization = auth;
	}
	const api = apisauce.create(config);

	const authToken = (data) => api.post('/auth/token?app=covid', data);
	const notificationsToken = (token) => api.post(`${v1}/notifications/token`, { token });
	const getProfile = (token = '123') => api.get(`${v1}/profile?token=${token}`);
	const updateProfile = (data, token = '123', isAuthContext = true) => {
		const authContext = localStore.getLoggedAs();

		if (isAuthContext) {
			return api.post(`${v1}/profile?token=${token}&authContext=${authContext}`, data);
		}

		return api.post(`${v1}/profile?token=${token}`, data);
	};
	const homeReport = (data, token = '123') => api.post(`${v1}/home-report?token=${token}`, data);
	const readNotification = (id, token = '123') =>
		api.post(`${v1}/personal-notification/${id}?token=${token}`);
	const getPersonalNotifications = (token = '123', data) =>
		api.get(`${v1}/personal-notifications?token=${token}${data}`);
	const getPersonalAlerts = (token = '123') => api.get(`${v1}/personal-alerts?token=${token}`); // TODO: Current work
	const readNotifications = (list, token = '123') =>
		api.post(`${v1}/personal-notifications/?token=${token}`, list);
	const readAllNotifications = () => api.post(`${v1}/personal-notifications/read-all`);
	const deleteNotifications = (list, token = '123') =>
		api.post(`${v1}/personal-notifications/delete?token=${token}`, list);
	const checkEmail = (email, token = '123') =>
		api.get(`/common/profileSXPE/checkEmail?email=${email}&token=${token}`);
	const updateAccountInformation = (data) => api.post(`${v1}/profile/main`, data);
	const passwordForgot = (data) => api.post('/common/passwordSXPE/email', { email: data });
	const setPassword = (data) => api.post('/auth/token?app=covid&action=register', data);
	const sendConfirmCodeEmail = (data) => api.post('/common/email-ownership/send', data);
	const confirmEmailCode = (data) => api.post('/common/email-ownership/confirm', data);
	const uploadProfile = (data) => api.post(`${v1}/profile`, data);
	const uploadAvatar = (data) => api.post(`${v1}/profile/avatar`, data, fileHeaders);
	const deleteAvatar = () => api.post(`${v1}/profile/avatar/delete`);
	const accountDeactivate = () => api.post(`${v1}/profile/deactivate`);
	const getCompanyInfo = (data) => api.get(`${v1}/company/company-details`);
	const getCompanyUsers = (data) => api.get(`${v1}/company/users?${toQueryString(data)}`);
	const uploadCompanyInfo = (data) => api.post(`${v1}/company/company-details`, data);
	const uploadCompanyLogo = (data) => api.post(`${v1}/company/logo`, data, fileHeaders);
	const deleteCompanyLogo = () => api.post(`${v1}/company/logo/delete`);
	const linkFacebookProfile = (data) => api.post(`${v1}/profile/linkFacebookProfile`, data);
	const linkGoogleProfile = (data) => api.post(`${v1}/profile/linkGoogleProfile`, data);
	const unlinkFacebookProfile = () => api.post(`${v1}/profile/unlinkFacebookProfile`);
	const unlinkGoogleProfile = () => api.post(`${v1}/profile/unlinkGoogleProfile`);
	const unlinkAppleProfile = () => api.post(`${v1}/profile/unlinkAppleProfile`);
	const linkEmailIntent = (data) => api.post(`${v1}/profile/linkEmailIntent`, data);
	const linkEmailConfirm = (data) => api.post(`${v1}/profile/linkEmailConfirm`, data);
	const updatePassword = (data) => api.post(`${v1}/profile/password`, data);
	const enableEmailNotifications = (flag = 'false') =>
		api.post(`${v1}/profile/enableEmailNotifications?enable=${flag}`);
	const getSlider = (data) => api.get(`${v1}/risk-distribution-quiz`);
	const setSlider = (data) => api.post(`${v1}/risk-distribution-quiz`, data);
	const quiz = (question_code, data) => api.post(`${v1}/quiz/answer/${question_code}`, data);
	const getDailyViewedLimitedList = () =>
		api.get(`${v1}/home-destination-report/daily-viewed-limited-list`);
	const riskComparisonQuiz = (data) => api.post(`${v1}/risk-comparison-quiz`, data);
	const deleteFcmToken = (data) => api.post(`${v1}/notifications/token/delete`, data);
	const getHomeDestinationReport = (data, token = '123') =>
		api.post(`${v1}/home-destination-report?token=${token}`, data);
	const getHomeDestinationReportHistory = (uuid, token = '123') =>
		api.get(`${v1}/home-destination-report/${uuid}`);
	const getHomeDestinationReportsHistoryList = (amountDays) =>
		api.get(`${v1}/home-destination-reports?days=${amountDays}`);
	const getHomeDestinationReportForm = (data) =>
		api.post(`${v1}/home-destination-report-form`, data);
	const getHomeDestinationReportFormHistory = (uuid) =>
		api.get(`${v1}/home-destination-report-form/${uuid}`);
	const getReportFormStatistics = (dateFrom, dateTo) =>
		api.get(
			`${v1}/form-report-statistics${
				dateFrom && dateTo ? `/?dateFrom=${dateFrom}&dateTo=${dateTo}` : ''
			}`,
		);
	const getPersonalAcademyTips = () => api.get(`${v1}/personal-academy-tips`);

	const geocoding = apisauce.create({
		baseURL: Config.mapbox.geocoding,
		headers: {
			'Cache-Control': 'no-cache',
		},
		timeout: 10000,
	});

	// const placesTypes = 'country,region,district,place,locality';
	const placesTypes = 'district,place';

	const searchPlaces = (query, types = placesTypes) =>
		geocoding.get(
			`/mapbox.places/${query}.json?types=${types}&access_token=${Config.mapbox.accessToken}`,
		);

	const buildPurchasedReportStub = (data) => api.post(`${v1}/build-purchased-report-stub`, data);

	const getOrttoApisauce = (orttoKey) => {
		const orttoApisauce = apisauce.create({
			baseURL: Config.apiBaseURL,
			headers: {
				'Cache-Control': 'no-cache',
				'X-Api-Key': orttoKey,
			},
			timeout: 120000,
		});
		return orttoApisauce;
	};

	const customOrttoActivity = (data, key) => {
		const ortto = getOrttoApisauce(key);
		return ortto.post('common/ortto/activities/create', data);
	};

	const personalAcademyTipOnRead = (id) => api.post(`${v1}/personal-academy-tip/${id}`);
	const getVicri = (data) => api.post('/common/vicri', data);

	return {
		apiInstance: api,
		authToken,
		notificationsToken,
		getProfile,
		updateProfile,
		homeReport,
		searchPlaces,
		getPersonalAlerts,
		readNotification,
		getPersonalNotifications,
		readNotifications,
		readAllNotifications,
		deleteNotifications,
		checkEmail,
		updateAccountInformation,
		passwordForgot,
		uploadAvatar,
		deleteAvatar,
		accountDeactivate,
		getCompanyInfo,
		getCompanyUsers,
		uploadCompanyInfo,
		uploadCompanyLogo,
		deleteCompanyLogo,
		linkFacebookProfile,
		linkGoogleProfile,
		unlinkFacebookProfile,
		unlinkGoogleProfile,
		unlinkAppleProfile,
		linkEmailIntent,
		linkEmailConfirm,
		updatePassword,
		enableEmailNotifications,
		getSlider,
		setSlider,
		quiz,
		getDailyViewedLimitedList,
		riskComparisonQuiz,
		deleteFcmToken,
		getHomeDestinationReport,
		getHomeDestinationReportHistory,
		getHomeDestinationReportsHistoryList,
		getHomeDestinationReportForm,
		getHomeDestinationReportFormHistory,
		getReportFormStatistics,
		getPersonalAcademyTips,
		buildPurchasedReportStub,
		customOrttoActivity,
		personalAcademyTipOnRead,
		getVicri,
		setPassword,
		sendConfirmCodeEmail,
		confirmEmailCode,
		uploadProfile,
	};
};

export default { create };
