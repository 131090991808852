import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context, Loader } from '../../components';
import { localStore, splitString } from '../../lib';

function AuthLoading() {
	const history = useHistory();

	const location = useLocation();

	const pathName = location.pathname;

	const { googleToken, isLogged, isLoader, firstStepDataExist } = useContext(Context);

	const reportId = () => {
		const arrToRoute = splitString(ROUTE.reportHomeDestination, '/');
		const arrToUrl = splitString(pathName, '/');

		return arrToRoute[1] == arrToUrl[1];
	};

	useEffect(() => {
		const timer = setInterval(() => {
			if (!googleToken) history.go(0);
			clearInterval(timer);
		}, 2000);

		return () => {
			clearInterval(timer);
		};
	}, [googleToken]);

	useEffect(() => {
		const isFirstAuth = localStore.getFirstAuth();

		if (!isLoader) {
			if (googleToken) {
				if (isLogged === true && reportId()) {
					history.push(pathName);
				} else if (isLogged && firstStepDataExist) {
					history.push(ROUTE.dashboard);
				} else if (isLogged && !firstStepDataExist) {
					history.push(ROUTE.signIn);
				} else if (!isLogged && isFirstAuth) {
					history.push(ROUTE.signIn);
				} else {
					history.push(ROUTE.welcome);
				}
			}
		}
	}, [googleToken, history, firstStepDataExist, isLogged, isLoader]);

	return (
		<div className="main-container">
			<div className="content">
				<Loader />
			</div>
		</div>
	);
}

export default AuthLoading;
